/* eslint-disable no-console */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { IconButton } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AVATAR } from "../../assets";
import Loader from "../../components/Loader";
import { NavbarWithMegaMenu } from "../../components/Navbar";
import Footer from "../../components/UI/Footer";
import { GetNewsDetails } from "../../services/Admin/service";

export default function () {
  const [urlParams] = useSearchParams();
  const id = urlParams.get("id");
  const { data: post, refetch } = useQuery(["posts", id], () =>
    GetNewsDetails(id),
  );

  useEffect(() => {
    refetch();
  }, [id]);

  const navigate = useNavigate();

  return (
    <>
      <NavbarWithMegaMenu />
      {!post && <Loader />}
      <div className='w-screen  '>
        <main className='mt-4 px-12'>
          <IconButton onClick={() => navigate("/news-and-events")}>
            <ArrowLeftCircleIcon className='mb-4 h-12' />
          </IconButton>
          <div className='block md:flex md:space-x-2 px-2 lg:p-0'>
            <>
              <div className='pb-20 overflow-hidden mb-4 md:mb-0 w-full  h-full relative rounded inline-block'>
                <img
                  alt=''
                  className=' w-full min-h-[400px] rounded object-cover'
                  src={post?.image}
                />
                <div className='p-4 mt-3'>
                  <span className='px-4 py-1 bg-black text-white inline-flex items-center justify-center mb-2'>
                    news
                  </span>
                  <h2 className='text-4xl font-semibold text-black leading-tight'>
                    {post?.title}
                  </h2>
                  <div className='flex mt-3'>
                    <img
                      alt=''
                      className='h-10 w-10 rounded-full mr-2 object-cover'
                      src={AVATAR}
                    />
                    <div>
                      <p className='font-semibold text-black text-sm'> Admin</p>
                      <p className='font-semibold text-gray-400 text-xs'>
                        {moment(post?.created_at).format("DD MMM")}
                      </p>
                    </div>
                  </div>
                  <h2 className='text-base font-normal text-black mt-7'>
                    {post?.body}
                  </h2>
                </div>
              </div>
            </>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
}
