import { NavigateFunction } from "react-router-dom";

export const handleNavigation = (data: string, navigate: NavigateFunction) => {
  switch (data) {
    // case UserType.LAWYER: {
    //   navigate("/lawyer");
    //   break;
    // }
    // case UserType.NON_LAWYER: {
    //   navigate("/non-lawyer");
    //   break;
    // }
    // case UserType.REGISTRAR_1: {
    //   navigate("/registrar-1");
    //   break;
    // }
    // case UserType.REGISTRAR_2: {
    //   navigate("/registrar-2");
    //   break;
    // }
    // case UserType.REGISTRAR_3: {
    //   navigate("/registrar-3");
    //   break;
    // }
    // case UserType.PRESIDING_JUDGE: {
    //   navigate("/presiding-judge");
    //   break;
    // }
    // case UserType.PRESIDING_MAGISTRATE: {
    //   navigate("/presiding-magistrate");
    //   break;
    // }
    // case UserType.JUDGE: {
    //   navigate("/judge");
    //   break;
    // }
    // case UserType.MAGISTRATE: {
    //   navigate("/magistrate");
    //   break;
    // }
    // case UserType.BAILIFF: {
    //   navigate("/bailiff");
    //   break;
    // }
    // case UserType.IT_STAFF: {
    //   navigate("/itstaff");
    //   break;
    // }
    // case UserType.CHIEF_JUDGE: {
    //   navigate("/chiefjudge");
    //   break;
    // }
    default: {
      navigate("/dashboard");
      break;
    }
  }
};
