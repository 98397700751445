/* eslint-disable jsx-a11y/alt-text */

import { Zoom } from "react-awesome-reveal";

import { Hon_Justice_A_R_Ozoemena } from "../../../assets";

function Welcome() {
  return (
    <div className='bg-brand_hero flex justify-center  items-center px-10 py-12 text-black z-30 w-full  max-md:px-5 max-md:max-w-full'>
      <div className='flex flex-col items-center mt-16 mb-16 max-w-full  max-md:my-10'>
        <Zoom triggerOnce={false}>
          <h2 className='text-3xl font-bold text-center '>
            Welcome to Enugu State Judiciary State
          </h2>
        </Zoom>

        <div className='  w-full mt-11 flex justify-center gap-10 items-start max-md:flex-col'>
          <div className=' flex-col  max-md:ml-0 max-md:w-full  lg:w-[30%]'>
            <img
              alt=''
              className='w-full object-cover hover:scale-110 rounded-3xl shadow-lg delay-300 transition-all ease-in-out duration-1000'
              loading='lazy'
              src={Hon_Justice_A_R_Ozoemena}
            />
          </div>

          <div className='flex flex-col ml-5 lg:w-[40%] max-md:ml-0 max-md:w-full '>
            <div className='flex flex-col  py-1.5 my-auto  max-md:max-w-full font-thin'>
              Welcome to the Website of Enugu State Judiciary. We are committed
              to ensuring disputes are resolved fairly, timely, transparently
              and economically by leveraging enabling technology with inherent
              seamless integration of the court processes, structures and
              administration. We provide automated case processing, payments and
              communication through this online court system to make it easy for
              various parties to a case to have timely access to pertinent
              case-related information. Over the years, our predecessors have
              done an amazing job in building a solid foundation for the Enugu
              State Judiciary. We believe that it is our responsibility now, to
              build on the success of our pioneers and predecessors, to reform
              the services of the Enugu State Judiciary for the benefit of the
              future generation. This reform requires a paradigm shift and must
              be underpinned by the use of modern technology, where appropriate.
              Every change requires collaboration and commitment from all
              stakeholders, and I would like to thank our colleagues and all
              those who are supporting our efforts.
            </div>
            <div className='mt-4 font-bold'>
              HON. Justice Afojulu Raymond Ozoemena
            </div>
            <div>Chief Judge of Enugu State</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
