/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import { PDF_ } from "../../assets";
import { NavbarWithMegaMenu } from "../../components/Navbar";
import ScrollContainer from "../../components/ScrollContainer";
import Footer from "../../components/UI/Footer";
import CourtDocumentsData from "../../data/DocumentsData";
import { HandleOpenDocument } from "../../utils";

export default () => {
  const [data, setData] = useState<any>();
  const [open, setOpen] = useState(false);
  return (
    <ScrollContainer>
      <NavbarWithMegaMenu />

      <div className='flex justify-center items-start gap-20 flex-wrap my-16 '>
        {CourtDocumentsData?.map((document_) => (
          <div className='w-[300px] h-[250px] bg-green-100/30 hover:scale-110 transition-all duration-500'>
            <img
              alt=''
              className='h-[150px] mt-4 mx-auto hover:rotate-12 hover:scale-110 transition-all duration-500 cursor-pointer'
              onClick={() => {
                setData(document_.path);
                setOpen(true);
              }}
              src={PDF_}
            />

            <div className='flex pl-4 font-medium justify-between flex-col h-[84px]'>
              <p className='text-sm'>{document_.name}</p>

              <a
                className='bg-green-500 cursor-pointer w-32 self-end px-4 py-2 mt-3 rounded'
                download={document_.path}
              >
                Download
              </a>
            </div>
          </div>
        ))}
      </div>

      <Footer />
      {HandleOpenDocument(open, setOpen, setData, data)}
    </ScrollContainer>
  );
};
