/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { AVATAR } from "../../assets";
import Loader from "../../components/Loader";
import { NavbarWithMegaMenu } from "../../components/Navbar";
import Footer from "../../components/UI/Footer";
import { GetNews } from "../../services/Admin/service";

export default function () {
  const { data, refetch } = useQuery("posts", GetNews);
  const navigate = useNavigate();
  return (
    <>
      <NavbarWithMegaMenu />
      {!data && <Loader />}
      <div className='w-screen '>
        <main className='mt-10 px-12'>
          <div className='block md:flex md:space-x-2 px-2 lg:p-0'>
            {data?.map((post: any, index: number) => (
              <>
                {index === 0 ? (
                  <a
                    className=' overflow-hidden mb-4 md:mb-0 w-full md:w-2/3 relative rounded inline-block'
                    href='#'
                    onClick={() => navigate(`details?id=${post.id}`)}
                    style={{ height: "24em" }}
                  >
                    <div
                      className='absolute left-0 bottom-0 w-full h-full z-10'
                      style={{
                        backgroundImage:
                          "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                      }}
                    />
                    <img
                      alt=''
                      className='absolute left-0 top-0 w-full h-full rounded z-0 object-cover'
                      src={post.image}
                    />
                    <div className='p-4 absolute bottom-0 left-0 z-20'>
                      <span className='px-4 py-1 bg-black text-gray-200 inline-flex items-center justify-center mb-2'>
                        news
                      </span>
                      <h2 className='text-4xl font-semibold text-gray-100 leading-tight'>
                        {post.title}
                      </h2>
                      <div className='flex mt-3'>
                        <img
                          alt=''
                          className='h-10 w-10 rounded-full mr-2 object-cover'
                          src={AVATAR}
                        />
                        <div>
                          <p className='font-semibold text-gray-200 text-sm'>
                            {" "}
                            Admin
                          </p>
                          <p className='font-semibold text-gray-400 text-xs'>
                            {moment(post?.created_at).format("DD MMM")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                ) : index === 1 ? (
                  <a
                    className='w-full md:w-1/3 relative rounded'
                    href='#'
                    onClick={() => navigate(`details?id=${post.id}`)}
                    style={{ height: "24em" }}
                  >
                    <div
                      className='absolute left-0 top-0 w-full h-full z-10'
                      style={{
                        backgroundImage:
                          "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                      }}
                    />
                    <img
                      alt=''
                      className='absolute left-0 top-0 w-full h-full rounded z-0 object-cover'
                      src={post.image}
                    />
                    <div className='p-4 absolute bottom-0 left-0 z-20'>
                      <span className='px-4 py-1 bg-black text-gray-200 inline-flex items-center justify-center mb-2'>
                        news
                      </span>
                      <h2 className='text-3xl font-semibold text-gray-100 leading-tight'>
                        {post.title}
                      </h2>
                      <div className='flex mt-3'>
                        <img
                          alt=''
                          className='h-10 w-10 rounded-full mr-2 object-cover'
                          src={AVATAR}
                        />
                        <div>
                          <p className='font-semibold text-gray-200 text-sm'>
                            {" "}
                            Admin
                          </p>
                          <p className='font-semibold text-gray-400 text-xs'>
                            {" "}
                            15 Aug{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                ) : null}
              </>
            ))}
          </div>

          <div className='w-full mt-10 mb-10 flex  justify-between items-start'>
            {data?.map((post: any, index: number) => (
              <>
                {index > 1 && (
                  <div className='block lg:w-[48%]  w-full'>
                    <a
                      className=' rounded w-full lg:flex mb-10'
                      href='#'
                      onClick={() => navigate(`details?id=${post.id}`)}
                    >
                      <div
                        className='h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75'
                        style={{
                          backgroundImage: `url(${post?.image})`,
                        }}
                        title='deit is very important'
                      />
                      <div className='bg-white rounded px-4 flex flex-col justify-between leading-normal overflow-hidden'>
                        <div>
                          <div className='mt-3 md:mt-0 text-gray-700 font-bold text-xl mb-2'>
                            {post?.title}
                          </div>
                          <p className='text-gray-700 text-base'>
                            {post?.body?.slice(0, 100)} ...
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                )}
              </>
            ))}
          </div>
          {/* </div> */}
        </main>

        <Footer />
      </div>
    </>
  );
}
