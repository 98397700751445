/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */

import React from "react";
import { Fade } from "react-awesome-reveal";

import { JUDGE } from "../../assets";
import { NavbarWithMegaMenu } from "../../components/Navbar";
import ScrollContainer from "../../components/ScrollContainer";
import Footer from "../../components/UI/Footer";

function ChiefRegistrars() {
  const [open, setOpen] = React.useState(1);

  const handleOpen = (value: React.SetStateAction<number>) =>
    setOpen(open === value ? 0 : value);

  return (
    <ScrollContainer>
      <NavbarWithMegaMenu />
      <div className='overflow-hidden flex justify-center items-center min-h-[400px] lg:h-[70vh] main-bg self-center px-16 pt-8 -mt-7 w-full bg-white  max-md:px-5 max-md:max-w-full'>
        <div className='flex gap-5 flex-col w-full'>
          <Fade
            className='flex flex-col w-full lg:w-[60%] mx-auto '
            direction='left'
            triggerOnce={false}
          >
            <div className='flex flex-col self-stretch my-auto max-md:mt-10 max-w-full'>
              <div className='text-7xl font-bold  text-green-100  max-md:max-w-full max-md:text-4xl max-md:leading-[49px] text-center'>
                Chief Registrars
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className=' flex-col justify-center flex items-center my-10'>
        <img
          alt=''
          className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
          src={JUDGE}
        />
        <p className='font-semibold text-center'>
          {" "}
          Justice Afojulu Raymond Ozoemena
        </p>
        <p className='text-center'>Chief Judge of Enugu State</p>
      </div>
      <div className='justify-center pb-12 items-start flex flex-wrap gap-4 lg:gap-10 w-full lg-w-4/5'>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
        <div className=' my-1'>
          <img
            alt=''
            className='h-64 border-8 mx-auto border-green-900 rounded-3xl'
            src={JUDGE}
          />
          <p className='font-semibold text-center'> Obama J.I. Trump</p>
          <p className='text-center'>Admin Judge, Enugu Judicial Division</p>
        </div>
      </div>
      <Footer />
    </ScrollContainer>
  );
}

export default ChiefRegistrars;
