/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Zoom } from "react-awesome-reveal";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import Button from "../../../components/button";
import Input from "../../../components/form/Input";
import { CreatePost } from "../../../services/Admin/service";

export default function () {
  const [file, setFile] = useState<any>([]);
  const createPostmutation = useMutation(CreatePost);
  const navigate = useNavigate();
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 50_000_000,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      // "image/webp": [".webp"],
    },
    onDrop: (acceptedFiles) => {
      setFile(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });
  const removeAll = () => {
    setFile([]);
  };
  const productFiles = file.map((item: any, index: number) => (
    <div className='w-full border border-gray-300 rounded-lg flex justify-between items-center px-4 py-2 mb-2'>
      <div className='flex justify-start items-center'>
        <img alt='' className='h-8 w-8 ' src={file[0]?.preview} />
      </div>
      <div className='flex justify-between items-center'>
        <div className='flex justify-end'>
          <button
            className='w-6 h-6 flex justify-center items-center rounded-full hover:opacity-70 text-white text-sm   bg-red-600'
            onClick={() => removeAll()}
            type='button'
          >
            x
          </button>
        </div>
      </div>
    </div>
  ));
  return (
    <div className='w-full lg:w-[80%] mx-auto'>
      <Zoom>
        <p className='font-bold text-xl text-center my-6'>Create News Post</p>
        <Formik
          initialValues={{
            title: "",
            blog: "",
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              const formData = new FormData();
              formData.append("title", values.title);
              formData.append("body", values.blog);
              formData.append("image", file[0]);

              const response = await createPostmutation.mutateAsync(formData);

              if (response) {
                toast.success(response.message, {
                  duration: 7000,
                });
                resetForm();
                file.length = 0;
                navigate("/admin/all-posts");
              }
            } catch (error: any) {
              toast.error(error?.response?.data?.message, {
                duration: 10_000,
              });
            }
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
            touched,
            errors,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form className='flex flex-col pt-3 md:pt-4 px-4  lg:pt-6 pb-16 lg:px-24'>
              <>
                <div className='w-full  mx-auto'>
                  <div className='flex justify-start items-center flex-wrap'>
                    <div className='flex flex-col   w-full mb-2 lg:mb-6'>
                      <label className='mb-3 flex justify-start items-center'>
                        Post Title
                      </label>
                      <Input
                        name='title'
                        placeholder='Enter the post title'
                        type='text'
                      />
                    </div>

                    <div className='flex flex-col w-full mb-2 lg:mb-6'>
                      <label className='mb-3 flex justify-start items-center'>
                        Post
                      </label>
                      <textarea
                        className='border-gray-200 appearance-none border rounded-md h-20 w-full resize-none flex justify-start p-4 text-sm'
                        name='blog'
                        onChange={handleChange}
                        placeholder='Enter post texts'
                        value={values.blog}
                      />
                    </div>
                    <div className='flex flex-col w-full mb-2 lg:mb-6'>
                      <label className='mb-3 flex justify-start items-center'>
                        Upload Image
                      </label>
                      <div className='flex flex-col  w-full lg:w-[48%]'>
                        <div className='' {...getRootProps()}>
                          <div className='w-full'>
                            {file.length > 0 ? (
                              productFiles
                            ) : (
                              <label className='flex  justify-start items-center h-12 w-full px-2 transition border border-gray-300 rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none'>
                                <p className='flex justify-between items-center w-full'>
                                  <span className='font-medium text-gray-600  text-center text-sm'>
                                    News Image
                                  </span>
                                  <a className='h-8 px-6  bg-[#CACACA] pt-1 pb-2 self-end  font-normal items-center text-xs text-black bg-BRAND-200 border border-BRAND-200 rounded-md '>
                                    upload
                                  </a>
                                </p>

                                <input
                                  className='hidden'
                                  name='file_upload'
                                  type='file'
                                  {...getInputProps()}
                                />
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Button
                  bgColor='bg-brand_indigo'
                  className='py-4  w-full rounded-lg'
                  hoverColor=''
                  isLoading={isSubmitting}
                  onClick={handleSubmit}
                  textColor='text-white'
                  title=' Submit'
                  type='button'
                />
              </>
            </Form>
          )}
        </Formik>
      </Zoom>
    </div>
  );
}
