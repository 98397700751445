import { API } from "../../config";
import {
  LoginPayloadTypes,
  LoginResponseTypes,
  RegisterRequestPayload,
  RegisterResponsePayload,
  ResendRequestPayloadTypes,
  ResendResponsePayloadTypes,
  VerifyRequestPayloadTypes,
  VerifyResponsePayloadTypes,
} from "./types";

export const register = async (data: RegisterRequestPayload) => {
  const response: RegisterResponsePayload = await API.post(
    "auth/register",
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response?.data;
};
export const login = async (data: LoginPayloadTypes) => {
  const response: LoginResponseTypes = await API.post("auth/login", data);
  return response.data;
};
export const forgotPassword = async (data: any) => {
  const response = await API.post("auth/forgot-password", data);
  return response.data;
};
export const VerifyEmail = async (data: VerifyRequestPayloadTypes) => {
  const response: VerifyResponsePayloadTypes = await API.post(
    "auth/verify-token",
    data,
  );
  return response?.data;
};
export const ResendToken = async (data: ResendRequestPayloadTypes) => {
  const response: ResendResponsePayloadTypes = await API.post(
    "auth/resend-token",
    data,
  );
  return response?.data;
};
export const UpdateProfileApi = async (data: any) => {
  const response = await API.post("profile/update", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response?.data;
};

export const getProfileApi = async () => {
  const response = await API.get("profile/show");
  return response?.data;
};
