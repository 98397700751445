/* eslint-disable react/no-array-index-key */
import React from "react";
import { Route, Routes } from "react-router-dom";

import { AdminRoutes } from "../data/AdminRoutes";
import { DashboardRoutes } from "../data/lawyer";
import { useAppSelector } from "../hooks";
import { DashboardLayout } from "../layouts/dashboard";
import { selectRole } from "../states/slices/authReducer";
import { UserType } from "../utils/Enums";

// main routing modaules

function MainRoutes() {
  const role = useAppSelector(selectRole);

  return (
    <div>
      <DashboardLayout>
        <Routes>
          {role === UserType.JUDGE &&
            DashboardRoutes.map((item, index) => (
              <Route
                element={<item.component />}
                key={index}
                path={item.path}
              />
            ))}
          {role === UserType.ADMIN &&
            AdminRoutes.map((item, index) => (
              <Route
                element={<item.component />}
                key={index}
                path={item.path}
              />
            ))}

          {/* {role === "NON LAWYER" &&
            NonlawyerRoutes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "PRESIDING JUDGE" &&
            presiding_judge_Routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "PRESIDING MAGISTRATE" &&
            presiding_magistrate_Routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "MAGISTRATE" &&
            magistrate_Routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "JUDGE" &&
            judge_Routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "BAILIFF" &&
            bailff_Routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "ADMIN" &&
            admin_Routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))} */}
        </Routes>
      </DashboardLayout>
    </div>
  );
}

export default MainRoutes;
