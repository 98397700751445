/* eslint-disable react/jsx-no-useless-fragment */
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { LuMoreVertical } from "react-icons/lu";

interface DatabaseTypes {
  handleDelete?: () => void;
  handleView?: () => void;
  handleRename?: () => void;
  handleFee?: () => void;
  handleQuery?: () => void;
  handleDetails?: () => void;
  handleActionTitle2?: () => void;
  handleActionTitle3?: () => void;
  isFee?: boolean;
  details?: boolean;
  isLoading?: boolean;
  isNotDelete?: boolean;
  query?: boolean;
  isRename?: boolean;
  actionTitle: string;
  actionTitle2?: string;
  actionTitle3?: string;
}

export default function DropDownOptions(props: DatabaseTypes) {
  return (
    <div className=' text-right '>
      <Menu as='div' className=' inline-block text-left'>
        <div>
          {props.isLoading ? (
            <button
              className='inline-flex w-full justify-center rounded-md bg-BRAND text-black px-2 py-1 text-xs font-medium  hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
              disabled={props.isLoading}
              type='button'
            >
              <svg
                className='w-5 h-5 mr-3 -ml-1 text-black animate-spin'
                fill='none'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  className='opacity-25'
                  cx={12}
                  cy={12}
                  r={10}
                  stroke='currentColor'
                  strokeWidth={4}
                />
                <path
                  className='opacity-75'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  fill='currentColor'
                />
              </svg>

              <span className='ml-2 text-xs'>processing...</span>
            </button>
          ) : (
            <Menu.Button className='inline-flex w-full justify-center rounded-md bg-BRAND text-black px-2 py-1 text-xs font-medium  hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
              <LuMoreVertical
                aria-hidden='true'
                className='ml-2 -mr-1 h-5 w-5 text-brand '
              />
            </Menu.Button>
          )}
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
            <div className='px-1 py-1'>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-[#C6D6FF]" : ""
                    } group flex w-full items-center  text-gray-900 rounded-md px-2 py-2 text-sm`}
                    onClick={props.handleView}
                    type='button'
                  >
                    {props.actionTitle}
                  </button>
                )}
              </Menu.Item>
            </div>
            {props.isRename && (
              <div className='px-1 py-1'>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-[#C6D6FF]" : ""
                      } group flex w-full items-center text-gray-900 rounded-md px-2 py-2 text-sm`}
                      onClick={props.handleRename}
                      type='button'
                    >
                      Rename
                    </button>
                  )}
                </Menu.Item>
              </div>
            )}
            {props.query && (
              <div className='px-1 py-1'>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-[#C6D6FF]" : ""
                      } group flex w-full items-center text-gray-900 rounded-md px-2 py-2 text-sm`}
                      onClick={props.handleQuery}
                      type='button'
                    >
                      Query
                    </button>
                  )}
                </Menu.Item>
              </div>
            )}
            {props.details && (
              <div className='px-1 py-1'>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-[#C6D6FF]" : ""
                      } group flex w-full items-center text-gray-900 rounded-md px-2 py-2 text-sm`}
                      onClick={props.handleDetails}
                      type='button'
                    >
                      View Details
                    </button>
                  )}
                </Menu.Item>
              </div>
            )}
            {props.actionTitle2 && (
              <div className='px-1 py-1'>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-[#C6D6FF]" : ""
                      } group flex w-full items-center text-gray-900 rounded-md px-2 py-2 text-sm`}
                      onClick={props.handleActionTitle2}
                      type='button'
                    >
                      {props.actionTitle2}
                    </button>
                  )}
                </Menu.Item>
              </div>
            )}
            {props.actionTitle3 && (
              <div className='px-1 py-1'>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-[#C6D6FF]" : ""
                      } group flex w-full items-center text-gray-900 rounded-md px-2 py-2 text-sm`}
                      onClick={props.handleActionTitle3}
                      type='button'
                    >
                      {props.actionTitle3}
                    </button>
                  )}
                </Menu.Item>
              </div>
            )}
            {props.isFee && (
              <div className='px-1 py-1'>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-[#C6D6FF]" : ""
                      } group flex w-full items-center text-gray-900 rounded-md px-2 py-2 text-sm`}
                      onClick={props.handleFee}
                      type='button'
                    >
                      Attach fee
                    </button>
                  )}
                </Menu.Item>
              </div>
            )}
            {props.isNotDelete ? (
              <></>
            ) : (
              <div className='px-1 py-1 '>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-[#C6D6FF]" : ""
                      } group flex w-full items-center text-gray-900 rounded-md px-2 py-2 text-sm`}
                      onClick={props.handleDelete}
                      type='button'
                    >
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
