import { Dashboard, MyFiles, Profile } from "../features/lawyer";
import AddNew from "../features/lawyer/CaseFolder/AddNew";
import Documents from "../features/lawyer/CaseFolder/documents";
import ScheduleHearing from "../features/lawyer/ScheduleHearing";

export const DashboardRoutes = [
  {
    id: "1",
    title: "Dashboard",
    component: Dashboard,
    path: "dashboard",
  },
  {
    id: "2",
    title: "My Files",
    component: MyFiles,
    path: "dashboard/cause-list",
  },

  {
    id: "3",
    title: "schedule-hearing",
    component: ScheduleHearing,
    path: "dashboard/schedule-hearing",
  },
  {
    id: "4",
    title: "Profile",
    component: Profile,
    path: "dashboard/settings",
  },

  {
    id: "10",
    title: "My Files",
    component: AddNew,
    path: "dashboard/create-folder-history/add-new",
  },
  {
    id: "10",
    title: "My Files",
    component: Documents,
    path: "dashboard/create-folder-history/documents",
  },
];
