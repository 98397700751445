/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import Modal from "../components/UI/modal";
import { Store } from "../states/store";

export const getJSONheader = () => {
  // const token = localStorage.getItem('token');
  const token = Store.getState().auths.token;
  return {
    headers: {
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getFormDataHeader = () => {
  const token = Store.getState().auths.token;
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
};

interface CurrencyTypes {
  iso: string;
  slug: "NGN" | "USD";
}

export const formatCurrency = (currencyType: CurrencyTypes) =>
  new Intl.NumberFormat(currencyType.iso, {
    style: "currency",
    currency: currencyType.slug,
  });

export function sumPrices(data: any) {
  let totalPrice = 0;

  for (const item of data) {
    totalPrice += item.price;
  }

  return totalPrice;
}

export const HandleOpenDocument = (
  open: boolean,
  setOpen: (argument0: boolean) => void,
  setFileUrl: (argument0: string) => void,
  fileUrl: string | undefined,
) => (
  <Modal
    close={() => {
      setOpen(false);
      setFileUrl("");
    }}
    maxWidth='lg'
    open={open}
  >
    <iframe className='w-full ' height={900} src={fileUrl} title='PDF Viewer' />
  </Modal>
);

export function getBackgroundColor(name: string): string {
  const colors: { [key: string]: string } = {
    a: "#FF5733",
    b: "#3366FF",
    c: "#33CC33",
    d: "#FFFF00",
    e: "#FFA500",
    f: "#800080",
    g: "#00FFFF",
    h: "#FF00FF",
    i: "#FFC0CB",
    j: "#00FF00",
    k: "#008080",
    l: "#A52A2A",
    m: "#808000",
    n: "#000080",
    o: "#800000",
    p: "#FF7F50",
    q: "#FFD700",
    r: "#4B0082",
    s: "#40E0D0",
    t: "#C0C0C0",
    u: "#EE82EE",
    v: "#DA70D6",
    w: "#F0E68C",
    x: "#708090",
    y: "#006400",
    z: "#ADD8E6",
  };

  const firstLetter = name.charAt(0).toLowerCase();
  return colors[firstLetter] || "#ADD8E6"; // If no matching color found, return white
}

export function hexToRGBA(hex: string, alpha: number): string {
  hex = hex.replace("#", "");
  const r = Number.parseInt(hex.slice(0, 2), 16);
  const g = Number.parseInt(hex.slice(2, 4), 16);
  const b = Number.parseInt(hex.slice(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
