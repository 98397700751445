/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Skeleton } from "@mui/material";
import React, { lazy, Suspense, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Zoom } from "react-reveal";
import { useNavigate } from "react-router-dom";

import { EMPTY } from "../../../assets";
import { CASE_CAUSELIST_COLUMNS } from "../../../components/table/columns/Lawyer";
import { TableComponent } from "../../../components/table/Table";
import Typography from "../../../components/Typography";
import { getCauselist } from "../../../services/Laywer/Lawyer.service";

const Loader = lazy(() => import("../../../components/Loader/index"));

function MyFiles() {
  const navigate = useNavigate();
  const [joinView, setJoinView] = useState(1);

  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const { data: causelist } = useQuery("getAllCases", getCauselist);

  const handleJoinView = () => {
    setJoinView(2);
  };
  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  return (
    <Suspense fallback={<Loader />}>
      <div className='pt-4 px-2 lg:px-8 pb-36 lg:pb-0'>
        <Typography textStyle='text-[20px] font-bold'>Causelist</Typography>
        <div className='w-full  pb-20 lg:pb-8'>
          <div className='w-full flex flex-row justify-end lg:justify-between flex-wrap items-start  pt-4 lg:pt-0'>
            {/* <div className=' lg:flex justify-between items-center w-full lg:w-1/3 mb-6 lg:mb-0'>
              <div className='bg-[#2E34D21A]  relative rounded-3xl  py-3 px-2 w-full  my-[2%] flex items-center'>
                <Button
                  bgColor='bg-brand_blue'
                  className='!text-base !py-1 px-4 absolute right-2 lg:right-3 top-2.5 rounded-xl'
                  onClick={() => {}}
                  textColor='text-white !text-sm'
                  title='Search'
                />
                <input
                  className='bg-[transparent] pl-4 border-0 outline-0 w-[100%] placeholder:text-xs  '
                  onChange={(event) => setSearch(event.target.value)}
                  placeholder='Search case'
                  type='text'
                  value={search}
                />
              </div>
            </div> */}
          </div>

          <div className='mt-8 '>
            <div className=' w-full lg:mb-0'>
              <Zoom>
                {causelist !== undefined && causelist?.length > 0 ? (
                  <div className=' border-b border-t-2 rounded-t-xl border-t-black border-[#F0F0F0] rounded-b-xl relative'>
                    <TableComponent
                      className='rounded-none rounded-t-0 '
                      COLUMNS={CASE_CAUSELIST_COLUMNS}
                      DATA={causelist}
                      page={page}
                      setPage={setPage}
                    />
                  </div>
                ) : causelist !== undefined && causelist?.length < 1 ? (
                  <div className='text-center text-xs w-full'>
                    <div className='flex flex-col justify-center items-center h-52'>
                      <img alt='' className='h-32' src={EMPTY} />
                      <p className='text-center text-sm mt-4'>
                        You have not created any case folder
                      </p>
                    </div>

                    {page > 1 && (
                      <div className='flex justify-end items-center w-full px-2 lg:px-4 py-2'>
                        <div className='flex justify-center items-center text-xs rounded-sm py-1  w-full '>
                          {page > 1 && (
                            <button
                              className=' cursor-pointer px-4 py-2 border-2 w-40 border-brand rounded-lg text-brand'
                              onClick={() => handlePrevious()}
                              type='button'
                              // disabled={!canPreviousPage}
                            >
                              Previous
                            </button>
                          )}

                          <span className='mx-4 text-xs'>
                            Page <strong>{page}</strong>{" "}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='w-full mb-3 px-8 flex justify-between items-center'>
                    <Skeleton
                      className='w-[50px] rounded-full mb-4'
                      height={50}
                      variant='rectangular'
                    />
                    <div className='w-[80%]'>
                      <Skeleton
                        className='w-full mb-4'
                        height={20}
                        variant='rectangular'
                      />
                      <Skeleton
                        className='w-[80%] mb-4'
                        height={20}
                        variant='rectangular'
                      />
                    </div>
                  </div>
                )}
              </Zoom>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
}

export default MyFiles;
