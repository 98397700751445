import { Dashboard, Profile } from "../features/Admin";
import AllPost from "../features/Admin/AllPost";
import AddNew from "../features/Admin/AllPost/AddNew";
import Documents from "../features/Admin/AllPost/documents";
import CreatePost from "../features/Admin/CreatePost";

export const AdminRoutes = [
  {
    id: "1",
    title: "Dashboard",
    component: Dashboard,
    path: "admin",
  },
  {
    id: "2",
    title: "All Posts",
    component: AllPost,
    path: "admin/all-posts",
  },

  {
    id: "3",
    title: "schedule-hearing",
    component: CreatePost,
    path: "admin/create-posts",
  },
  {
    id: "4",
    title: "Profile",
    component: Profile,
    path: "admin/settings",
  },

  {
    id: "10",
    title: "My Files",
    component: AddNew,
    path: "admin/create-folder-history/add-new",
  },
  {
    id: "10",
    title: "My Files",
    component: Documents,
    path: "admin/create-folder-history/documents",
  },
];
