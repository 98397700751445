/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { Zoom } from "react-reveal";
import { useNavigate } from "react-router-dom";

import { login_bg } from "../../assets";
import Button from "../../components/button";
import Input from "../../components/form/Input";
import { NavbarWithMegaMenu } from "../../components/Navbar/index";
import { useAppDispatch } from "../../hooks";
import { login } from "../../services/auth/auth.service";
import { LoginPayloadTypes } from "../../services/auth/types";
import { setRole, setToken, setUser } from "../../states/slices/authReducer";
import { handleNavigation } from "../../utils/functions/Navigation";

function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const LoginMuation = useMutation(login);

  return (
    <>
      <NavbarWithMegaMenu />
      <div className='flex flex-col justify-center bg-black '>
        <div className='flex overflow-hidden relative flex-col justify-center items-center px-10 py-12 w-full h-[90vh]  max-md:px-5 max-md:max-w-full'>
          <img
            alt=''
            className='object-cover absolute inset-0 h-full w-screen bg-no-repeat'
            loading='lazy'
            srcSet={login_bg}
          />
          <div className='flex relative flex-col items-center py-12 mt-20 mb-12 max-w-full bg-white border-solid  border-[0.89px] border-[color:var(--COLOUR-2,#CACACA)] rounded-[32px] w-[611px] h-full max-md:my-10'>
            <div className='mt-1.5 text-2xl font-semibold text-black whitespace-nowrap'>
              Login
            </div>

            <Zoom>
              <Formik
                initialValues={{
                  password: "",

                  email: "",
                }}
                // validationSchema={loginSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  const payload: LoginPayloadTypes = {
                    email: values.email,
                    password: values.password,
                  };
                  try {
                    const response = await LoginMuation.mutateAsync(payload);

                    if (response) {
                      const { data, message } = response;
                      toast.success(message, {
                        duration: 7000, // Duration for which the toast is displayed (in milliseconds)
                      });
                      resetForm();
                      dispatch(setUser(data));
                      dispatch(setRole(data.role));
                      dispatch(setToken(data.token));

                      handleNavigation(data?.role, navigate);
                    }
                  } catch (error: any) {
                    toast.error(error?.response?.data?.message, {
                      duration: 10_000,
                    });
                  }
                }}
              >
                {({
                  handleSubmit,
                  isSubmitting,
                  values,
                  handleChange,
                  /* and other goodies */
                }) => (
                  <Form className='flex flex-col pt-3 md:pt-4 px-4 md:px-8 lg:pt-6'>
                    <div className='flex justify-between items-center flex-wrap '>
                      <div className='flex flex-col pt-2  w-full'>
                        {/* <label htmlFor="email" className="text-sm">
                        Email
                      </label> */}
                        <Input
                          name='email'
                          placeholder='Enter your email address'
                          type='text'
                        />
                      </div>

                      <div className='flex flex-col pt-2 w-full '>
                        {/* <label htmlFor="password" className="text-sm">
                        Password
                      </label> */}
                        <Input
                          isPassword
                          name='password'
                          placeholder='Enter your password'
                          type='text'
                        />
                      </div>
                    </div>
                    <a
                      className='cursor-pointer'
                      onClick={() => navigate("/forgot-password")}
                    >
                      {/* <p className='text-right text-red-700 text-sm'>
                      Forgot password?
                    </p> */}
                    </a>

                    <Button
                      bgColor='bg-brand_blue'
                      className=' mb-2  mt-4  w-full py-4 rounded-2xl'
                      isLoading={isSubmitting}
                      onClick={handleSubmit}
                      textColor='text-white'
                      title='Log In'
                      type='button'
                    />
                  </Form>
                )}
              </Formik>
            </Zoom>
            {/* <div className='text-center pt-4 pb-12'>
            <p className='text-sm'>
              Don't have an account?{" "}
              <Link className='text-brand_blue font-semibold' to='/signup'>
                Sign up
              </Link>
            </p>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
