/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import React from "react";
import { Fade } from "react-awesome-reveal";

import { ORGANOGRAM } from "../../assets";
import { NavbarWithMegaMenu } from "../../components/Navbar";
import ScrollContainer from "../../components/ScrollContainer";
import Footer from "../../components/UI/Footer";

function Icon({ id, open }: any) {
  return (
    <svg
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
      fill='none'
      stroke='currentColor'
      strokeWidth={2}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.5 8.25l-7.5 7.5-7.5-7.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
function About() {
  const [open, setOpen] = React.useState(1);

  const handleOpen = (value: React.SetStateAction<number>) =>
    setOpen(open === value ? 0 : value);

  return (
    <ScrollContainer>
      <NavbarWithMegaMenu />
      <div className='overflow-hidden flex justify-center items-center min-h-[400px] lg:h-[70vh] about-bg  self-center px-16 pt-8 -mt-7 w-full bg-white  max-md:px-5 max-md:max-w-full'>
        <div className='flex gap-5 flex-col w-full'>
          <Fade
            className='flex flex-col w-full lg:w-[60%] mx-auto '
            direction='left'
            triggerOnce={false}
          >
            <div className='flex flex-col self-stretch my-auto max-md:mt-10 max-w-full'>
              <div className='text-7xl font-bold  text-green-100  max-md:max-w-full max-md:text-4xl max-md:leading-[49px] text-center'>
                About Enugu State Judiciary
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className=' justify-center flex items-center my-10'>
        <div className=' w-full lg:w-3/5 mx-auto'>
          <Accordion
            icon={<Icon id={1} open={open} />}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            open={open === 1}
            placeholder={undefined}
          >
            <AccordionHeader
              className='text-base font-semibold font-clash'
              onClick={() => handleOpen(1)}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              placeholder={undefined}
            >
              About Enugu Judiciary
            </AccordionHeader>
            <AccordionBody>
              The Enugu State Judiciary is the third arm of the government of
              Enugu State. It was established on 27th August 1991, following the
              creation of Enugu State as one of the Federating States of the
              Federal Republic of Nigeria. The Enugu State Judiciary is
              comprised of the High Court of Justice of Enugu State, the
              Magistrate Court of Enugu State, the Customary Courts of Enugu
              State, and the Judicial Service Commission of Enugu State, and is
              constitutionally vested with the duty of administering the law and
              justice in the State. This arm of the Government of Enugu State is
              headed by the Chief Judge of Enugu State, who is the head of
              courts in the State as well as Chairman, Judicial Service
              Commission of the State. The headquarters of the Enugu State
              Judiciary is the Anthony I. Iguh High Court Complex, Awka, Enugu
              State. The High Court of the State is the superior court of
              record, and pursuant to section of the 1999 Constitution of
              Nigeria, as amended, lower courts to writ Magistrates Courts and
              Customary Courts have been established and are in operation in the
              State. To enhance access to justice, there are currently ten
              Judicial Divisions of the High Court, for the time being. In the
              same vein, there are thirty-four (34) Customary Courts spread
              across the twenty-one Local Government Areas of the State. With
              this introduction, I welcome you to the Enugu State Judiciary
              Website. We have no doubt that your stay and search through this
              site will be most rewarding. Welcome, as we unveil the New Enugu
              State Judiciary!!!
            </AccordionBody>
          </Accordion>
          <Accordion
            icon={<Icon id={2} open={open} />}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            open={open === 2}
            placeholder={undefined}
          >
            <AccordionHeader
              className='text-base font-semibold font-clash'
              onClick={() => handleOpen(2)}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              placeholder={undefined}
            >
              Enugu Judiciary Organogram
            </AccordionHeader>
            <AccordionBody>
              <img alt='' className='' src={ORGANOGRAM} />
            </AccordionBody>
          </Accordion>
          <Accordion
            icon={<Icon id={3} open={open} />}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            open={open === 3}
            placeholder={undefined}
          >
            <AccordionHeader
              className='text-base font-semibold font-clash'
              onClick={() => handleOpen(3)}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              placeholder={undefined}
            >
              Historical Land Marks
            </AccordionHeader>
            <AccordionBody>
              Under the leadership of the Chief Judge of Enugu State, Honourable
              Justice Opeyemi Oke, a total number of Twenty-Three Thousand, Nine
              Hundred (23,900) cases were disposed by the High Court and
              Magistrate Courts between October 2017 and September 2018.
              <div className='mt-2' />
              Establishment of the Small Claims Courts in all the Magisterial
              Districts, the first of its kind in Nigeria on the 23rd of April,
              2018 by Honourable Justice Opeyemi Oke. Establishment of Special
              Offenses Courts and Sexual Offences Courts on the 1st day of
              February 2018, by Honourable Justice Opeyemi Oke. Introduction of
              Judiciary Information System (JIS) Electronic filing innovation by
              Honourable Justice Inumidun Enitan Akande to the High Court of
              Lagos State in December 2010. To continue the technology
              innovation, in 2013 Honourable Justice Adeyoola Ayotunde Phillips
              issued Practice Direction stating that "All processes/matters
              filed in Lagos High Court must be filed through the Judiciary
              Information System (JIS) as from 2nd October, 2013". JIS and
              e-Filing was commissioned on the 2nd day of October 2013 by
              Honourable Justice Adeyoola Ayotunde Philips.
              <div className='mt-2' />
              Enugu Multi-door Courthouse (LMDC) an Alternative Disputes
              Resolution (ADR) Center was the first in Nigeria, established on
              the 11th day of June, 2002 by Honorable Justice I.A Sotuminu,
              Chief Judge of Lagos State. Establishment of the Fast Track Court
              in the Commercial Division of The Lagos State High Court, Lagos
              Division, on 26th day April 2004.
            </AccordionBody>
          </Accordion>
          <Accordion
            icon={<Icon id={4} open={open} />}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            open={open === 4}
            placeholder={undefined}
          >
            <AccordionHeader
              className='text-base font-semibold font-clash'
              onClick={() => handleOpen(4)}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              placeholder={undefined}
            >
              Enugu High Courts
            </AccordionHeader>
            <AccordionBody>
              The establishment of a High Court for each State is provided for
              in section 270 of the constitution. The High Court of each State
              consist of a Chief Judge of a State and such number of Judges of
              the High Court as maybe prescribed by a law of the House of
              Assembly of the State. The High Court of Anambra State has
              jurisdiction to hear and determine any civil proceedings in which
              the existence or extent of a legal right, power, duty, liability,
              privilege, interest, obligation, or claim is in issue, or to hear
              and determine any criminal proceedings involving or relating to
              any penalty, forfeiture, punishment, or other liability in respect
              of an offence committed by any person. It equally has jurisdiction
              over Appeal in all cases whether civil or criminal emanating from
              the Magistrate Court or other lower Courts.
              <div className='mt-2' />
              The establishment of a High Court for each State is provided for
              in section 270 of the constitution. The High Court of each State
              consist of a Chief Judge of a State and such number of Judges of
              the High Court as maybe prescribed by a law of the House of
              Assembly of the State. The High Court of Anambra State has
              jurisdiction to hear and determine any civil proceedings in which
              the existence or extent of a legal right, power, duty, liability,
              privilege, interest, obligation, or claim is in issue, or to hear
              and determine any criminal proceedings involving or relating to
              any penalty, forfeiture, punishment, or other liability in respect
              of an offence committed by any person. It equally has jurisdiction
              over Appeal in all cases whether civil or criminal emanating from
              the Magistrate Court or other lower Courts.
              <div className='mt-2' />
              The establishment of a High Court for each State is provided for
              in section 270 of the constitution. The High Court of each State
              consist of a Chief Judge of a State and such number of Judges of
              the High Court as maybe prescribed by a law of the House of
              Assembly of the State. The High Court of Anambra State has
              jurisdiction to hear and determine any civil proceedings in which
              the existence or extent of a legal right, power, duty, liability,
              privilege, interest, obligation, or claim is in issue, or to hear
              and determine any criminal proceedings involving or relating to
              any penalty, forfeiture, punishment, or other liability in respect
              of an offence committed by any person. It equally has jurisdiction
              over Appeal in all cases whether civil or criminal emanating from
              the Magistrate Court or other lower Courts.
            </AccordionBody>
          </Accordion>
          <Accordion
            icon={<Icon id={5} open={open} />}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            open={open === 5}
            placeholder={undefined}
          >
            <AccordionHeader
              className='text-base font-semibold font-clash'
              onClick={() => handleOpen(5)}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              placeholder={undefined}
            >
              Enugu Magistrate Courts
            </AccordionHeader>
            <AccordionBody>
              The Magistrate Court is a creation of the State House of Assembly,
              and it is a Court of Summary trials. Pleadings are not exchange in
              the Magistrate Court. The Magistrate Court is equivalent of what
              the call district Court in the Northern part of the Country.
              Virtually all Criminal cases starts in a Magistrates Court about
              95% of those case will be complete there.
              <div className='mt-2' />
              The Magistrate Court is a creation of the State House of Assembly,
              and it is a Court of Summary trials. Pleadings are not exchange in
              the Magistrate Court. The Magistrate Court is equivalent of what
              the call district Court in the Northern part of the Country.
              Virtually all Criminal cases starts in a Magistrates Court about
              95% of those case will be complete there.
              <div className='mt-2' />
              The Magistrate Court is a creation of the State House of Assembly,
              and it is a Court of Summary trials. Pleadings are not exchange in
              the Magistrate Court. The Magistrate Court is equivalent of what
              the call district Court in the Northern part of the Country.
              Virtually all Criminal cases starts in a Magistrates Court about
              95% of those case will be complete there.
            </AccordionBody>
          </Accordion>
        </div>
      </div>

      <Footer />
    </ScrollContainer>
  );
}

export default About;
