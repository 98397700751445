// import Swiper core and required modules
import { useNavigate } from "react-router-dom";
import { A11y, Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { LAWYER, VIRTUALHEARING, WIG } from "../../assets";

const ServiceCard = ({ icon, title, details, image }: any) => (
  <div className='shadow-box-shadow rounded-2xl w-full h-[320px]'>
    <div className=' flex w-full items-center  justify-center rounded-t-3xl bg-primary'>
      <img
        alt=''
        className='w-full h-40  object-cover rounded-t-2xl'
        src={image}
      />
    </div>
    <div className='mb-9 rounded-[20px] pb-5 bg-white   md:px-7 xl:px-5'>
      <h4 className='mb-[14px] text-2xl  pt-5 font-semibold '>{title}</h4>
      <p className=''>{details}</p>
    </div>
  </div>
);

export default () => {
  const navigate = useNavigate();
  return (
    <Swiper
      // install Swiper modules
      autoplay={{
        delay: 2000,
        disableOnInteraction: true,
      }}
      modules={[Navigation, A11y, Autoplay]}
      // onSlideChange={() => console.log("slide change")}
      // onSwiper={(swiper) => console.log(swiper)}
      // pagination={{ clickable: true }}
      // scrollbar={{ draggable: true }}
      slidesPerView={3}
      spaceBetween={30}
      style={{ height: 400 }}
    >
      <SwiperSlide>
        <ServiceCard
          details='The Litigation Module is a technology solution aimed at speeding up the litigation process for the judiciary system in Enugu State.'
          image={WIG}
          title='Litigation Module'
        />
      </SwiperSlide>
      <SwiperSlide>
        <ServiceCard
          details='Virtual Hearing is a technology solution aimed at hearing court cases virtually from any remote location you desire.'
          image={VIRTUALHEARING}
          title='Virtual Hearing'
        />
      </SwiperSlide>

      <SwiperSlide>
        <ServiceCard
          details='e-Affidavit is an online platform where users get to apply and get approved affidavits with verification numbers.'
          image={LAWYER}
          title='e-Affidavit
          '
        />
      </SwiperSlide>
      <SwiperSlide>
        <ServiceCard
          details='e_Lis Pendens is an online platform that helps users to ensure that their property of interest is litigation-free.'
          image={LAWYER}
          title='e-Lis Pendens'
        />
      </SwiperSlide>
      <SwiperSlide>
        <ServiceCard
          details='e-Probate Platform  makes settling the estate of a loved one easy. It simplifies the process and allows you to complete everything online.'
          image={LAWYER}
          title='e-Probate'
        />
      </SwiperSlide>
    </Swiper>
  );
};
