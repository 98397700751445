import { API } from "../../config/index";
import path from "./path";
import {
  CaseDocumentResponse,
  CaseFolderResponseTypes,
  JoinCasePayloadTypes,
  JoinCaseResponseTypes,
} from "./types";

export const CreateCaseApi = async (data: any) => {
  const response: CaseFolderResponseTypes = await API.post(
    path.createCaseFolder,
    data,
  );
  return response.data;
};
export const getAllDocumentsApi = async (id: string | null) => {
  const response: CaseDocumentResponse = await API.get(
    path.getCaseDocuments(id),
  );
  return response?.data;
};
export const joinCaseApi = async (payload: JoinCasePayloadTypes) => {
  const response: JoinCaseResponseTypes = await API.post(
    path.joinCase,
    payload,
  );
  return response?.data;
};
export const RenameCaseApi = async (payload: any, id: string | undefined) => {
  const response = await API.post(path.renameDocuments(id), payload);
  return response?.data;
};
export const addDocumentCaseApi = async (
  payload: JoinCasePayloadTypes | any,
  id: string | null,
) => {
  const response: JoinCaseResponseTypes = await API.post(
    path.addDocument(id),
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response?.data;
};
export const DeleteDocumentCaseApi = async (id: string) => {
  const response = await API.delete(path.deleteDocuments(id));
  return response?.data;
};
export const getDashboardAnalytics = async () => {
  const response = await API.get(path.dashboard);
  return response?.data?.data;
};
export const getCauselist = async () => {
  const response = await API.get(path.getCauselist);
  return response?.data?.data;
};
export const SubmitCaseFolder = async (id: string | null) => {
  const response = await API.post(path.submitCase(id));
  return response?.data;
};
export const AddCauselist = async (payload: any) => {
  const response = await API.post(path.addCauselist, payload);
  return response?.data;
};
