/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import { IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { FaPlusCircle } from "react-icons/fa";
import { MdGroupAdd } from "react-icons/md";
import { PiFolderNotchOpenBold } from "react-icons/pi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { PDF, UPLOAD } from "../../../assets";
import Button from "../../../components/button";
import Input, { FormInput } from "../../../components/form/Input";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import createCaseSchema from "../../../schemas/createCase";
import {
  addDocumentCaseApi,
  CreateCaseApi,
  DeleteDocumentCaseApi,
  getAllDocumentsApi,
  SubmitCaseFolder,
} from "../../../services/Laywer/Lawyer.service";
import { DocumentTypes } from "../../../services/Laywer/types";
import {
  addValue,
  getPartyInfo,
  removeValue,
  resetValue,
} from "../../../states/slices/partyInfo.slice";
import { PartyInfoTypes } from "../../../types";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, rgb(30, 6, 245) 0%, rgb(1, 3, 69) 50%, rgb(0, 85, 255) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, rgb(30, 6, 245) 0%, rgb(1, 3, 69) 50%, rgb(0, 85, 255) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(30, 6, 245) 0%, rgb(1, 3, 69) 50%, rgb(0, 85, 255) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(30, 6, 245) 0%, rgb(1, 3, 69) 50%, rgb(0, 85, 255) 100%)",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <MdGroupAdd />,
    // 2: <GoLaw />,
    2: <PiFolderNotchOpenBold />,
  };

  return (
    <ColorlibStepIconRoot
      className={className}
      ownerState={{ completed, active }}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ["Description of parties", "Upload case documents"];

function AddNew() {
  const [title, setTitle] = useState<any>("");
  const [loading, setLoading] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [required, setRequired] = useState<string>("");
  const [file, setFile] = useState<any>([]);
  const [dataPayload, setDataPayload] = useState<any>();

  const [step, setStep] = useState(0); // Changed initial step to 0

  const partyInfo = useAppSelector(getPartyInfo);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const createCaseMutation = useMutation(CreateCaseApi);
  const deleteDocumentMutation = useMutation(DeleteDocumentCaseApi);

  const { data: getDocuments, refetch } = useQuery(
    ["getDocument", dataPayload?.id],
    () => getAllDocumentsApi(dataPayload?.id),
  );

  const handleAddDocument = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (file?.length < 1 || file === null) {
      setRequired("required");
      toast.error("PDF is required.", {
        duration: 7000,
      });
      setLoading(false);
    } else {
      try {
        const formData = new FormData();

        formData.append("title", title);
        formData.append("file_path", file[0]);

        const response = await addDocumentCaseApi(formData, dataPayload?.id);

        if (response) {
          setFile(null);
          setLoading(false);
          toast.success(response.message, { duration: 7000 });
          setRequired("");
          setTitle("");
          refetch();
        }
      } catch (error: any) {
        setLoading(false);
        toast.error(error.response.data.message, { duration: 7000 });
      }
    }
  };

  const handleDeleteExistingDocument = async (documentId: string) => {
    try {
      const response = await deleteDocumentMutation.mutateAsync(documentId);
      if (response) {
        refetch();
        toast.success(response.message, {
          duration: 5000,
        });
      }
    } catch (error: any) {
      toast.success(error?.response.data?.message, {
        duration: 5000,
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 50_000_000,
    accept: {
      "application/pdf": [".pdf"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    onDrop: (acceptedFiles) => {
      setFile(
        acceptedFiles?.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });
  const removeAll = () => {
    setFile([]);
  };

  const productFiles = file?.map((item: any, index: number) => (
    <div className='w-[80%]'>
      <div className='flex justify-start items-center'>
        <img alt='' className='h-20 w-20 ' src={PDF} />
      </div>
      <div className='flex justify-start items-center'>
        <p
          className='text-center text-green-600 text-sm font-semibold'
          key={index}
        >
          {item.name}
        </p>

        <button
          className='hover:opacity-70 text-white text-xs ml-6 font-bold  rounded-full h-6 w-6 flex items-center justify-center bg-red-600'
          onClick={() => removeAll()}
          type='button'
        >
          X
        </button>
      </div>
    </div>
  ));

  const handleNext = async () => {
    let hasClaimant = false;
    let hasDefendant = false;

    for (let index = 0; index < partyInfo?.length; index++) {
      if (partyInfo[index].type === "CLAIMANT") {
        hasClaimant = true;
      } else if (partyInfo[index].type === "DEFENDANT") {
        hasDefendant = true;
      }
    }

    if (!hasClaimant && !hasDefendant) {
      setRequired("Defendant and Claimant information are required");
    } else if (!hasClaimant) {
      setRequired("Claimant information is required");
    } else if (hasDefendant) {
      try {
        setIsLoading(true);
        const payload = {
          parties: partyInfo,
        };

        const response = await createCaseMutation.mutateAsync(payload);
        if (response) {
          toast.success(response.message, {
            duration: 10_000,
          });
          setIsLoading(false);
          setDataPayload(response.data);
          setStep((previousActiveStep) => previousActiveStep + 1);
          dispatch(resetValue());
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message, {
          duration: 10_000,
        });
        setIsLoading(false);
      }
    } else {
      setRequired("Defendant information is required");
    }
  };

  const handleBack = () => {
    navigate("/lawyer/create-folder-history");
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await SubmitCaseFolder(dataPayload?.id);

    if (response) {
      setIsLoading(false);
      toast.success(response.message, {
        duration: 5000,
      });
      navigate("/lawyer/create-folder-history");
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className='flex flex-col justify-center items-center pr-6 pb-12 bg-white max-md:flex-wrap max-md:pr-5 w-full bg-blue-50/50'>
      <div className='px-8 pt-4 pb-12  w-full  lg:w-[50%] bg-white rounded-lg mt-8'>
        <div className='mt-2 ml-14 text-xl font-semibold text-black whitespace-nowrap  max-md:ml-2.5 text-center mb-6'>
          Create New Case Folder
        </div>
        <Stack spacing={4} sx={{ width: "100%" }}>
          <Stepper
            activeStep={step} // Changed active step to 'step'
            alternativeLabel
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
        <div className=''>
          {step === 0 && ( // Render step 1 content
            <Formik
              initialValues={{
                name: "",
                phone_number: "",
                email: "",
                address: "",
                whatsapp_number: "",
                type: "",
              }}
              onSubmit={async (values, { resetForm }) => {
                dispatch(addValue(values));
                resetForm();
              }}
              validationSchema={createCaseSchema}
            >
              {({
                handleSubmit,
                values,
                handleChange,
                touched,
                errors,
                /* and other goodies */
              }) => (
                <Form className='flex flex-col pt-3 md:pt-4 px-4 md:px-10 lg:pt-6'>
                  <>
                    <div className='mt-8 ml-14   whitespace-nowrap max-md:mt-10 max-md:ml-2.5 flex justify-between items-start flex-wrap'>
                      <p className='text-xl font-semibold text-black' />
                    </div>

                    <div className='flex justify-start items-center flex-wrap'>
                      <div className='flex flex-col   w-full mb-2 lg:mb-6'>
                        <label className='mb-3 flex justify-start items-center'>
                          Add a Claimant and Defendant
                          {/* <FaChevronDown className="ml-4" /> */}
                        </label>
                        <select
                          className={`border-gray-300 appearance-none border placeholder:text-sm rounded-lg w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline  placeholder:text-gray-500 `}
                          name='type'
                          onChange={handleChange}
                          value={values.type}
                        >
                          <option className='text-gray-200' value=''>
                            Select an claimant or defender
                          </option>
                          <option value='CLAIMANT'>Claimant</option>
                          <option value='DEFENDANT'>Defendant</option>
                        </select>
                        {touched.type && errors.type && (
                          <span className='text-red-500 text-sm'>
                            {errors.type}
                          </span>
                        )}
                      </div>

                      <div className='flex flex-col   w-full mb-2'>
                        <Input name='name' placeholder='Name' type='text' />
                      </div>

                      <div className='flex flex-col w-full mb-2'>
                        <Input
                          name='email'
                          placeholder='Email address'
                          type='text'
                        />
                      </div>
                      <div className='flex flex-col w-full mb-2'>
                        <Input
                          name='address'
                          placeholder='Contact Address'
                          type='text'
                        />
                      </div>
                      <div className='flex flex-col w-full mb-2'>
                        <Input
                          name='phone_number'
                          placeholder='Phone number'
                          type='text'
                        />
                      </div>
                      <div className='flex flex-col w-full mb-2'>
                        <Input
                          name='whatsapp_number'
                          placeholder='Whatsapp number'
                          type='text'
                        />
                      </div>
                    </div>
                    <div className='flex justify-end items-center'>
                      <IconButton onClick={() => handleSubmit()}>
                        <FaPlusCircle className='text-brand_blue' />
                      </IconButton>
                    </div>

                    {partyInfo?.map((item: PartyInfoTypes, index: number) => (
                      <>
                        <div className='flex flex-row justify-between  items-start mt-9 w-full flex-wrap '>
                          <div className=' justify-center items-start py-3 pr-16 pl-3.5 mt-4 max-w-full text-sm text-black whitespace-nowrap rounded border-solid border-[0.89px] border-[color:var(--COLOUR-2,#CACACA)] w-full bg-blue-100 max-md:pr-5'>
                            {item?.type}
                          </div>
                          <div className='justify-center items-start py-3 pr-16 pl-3.5 mt-4 max-w-full text-sm text-black whitespace-nowrap rounded border-solid border-[0.89px] border-[color:var(--COLOUR-2,#CACACA)] w-full bg-blue-100 max-md:pr-5'>
                            {item?.name}
                          </div>

                          <div className='justify-center items-start py-3 pr-16 pl-3.5 mt-4 max-w-full text-sm text-black whitespace-nowrap rounded border-solid border-[0.89px] border-[color:var(--COLOUR-2,#CACACA)] w-full bg-blue-100 max-md:pr-5'>
                            {item?.email}
                          </div>
                          <div className='justify-center items-start py-3 pr-16 pl-3.5 mt-8 max-w-full text-sm text-black whitespace-nowrap rounded border-solid border-[0.89px] border-[color:var(--COLOUR-2,#CACACA)] w-full bg-blue-100 max-md:pr-5'>
                            {item?.phone_number}
                          </div>

                          <div className='justify-center items-start py-3 pr-16 pl-3.5 mt-4 max-w-full text-sm text-black rounded border-solid border-[0.89px] border-[color:var(--COLOUR-2,#CACACA)] w-full bg-blue-100 max-md:pr-5'>
                            {item?.whatsapp_number}
                          </div>
                          <div className='justify-center items-start py-3 pr-16 pl-3.5 mt-8 max-w-full text-sm text-black whitespace-nowrap rounded border-solid border-[0.89px] border-[color:var(--COLOUR-2,#CACACA)] w-full bg-blue-100 max-md:pr-5'>
                            {item?.address}
                          </div>
                        </div>

                        <div className='w-full flex justify-end'>
                          <IconButton
                            onClick={() => {
                              dispatch(removeValue(item));
                            }}
                            type='button'
                          >
                            <RiDeleteBin5Line className='text-red-600 mt-2' />
                          </IconButton>
                        </div>
                        {partyInfo?.length - 1 === index ? null : (
                          <div className='border-b-4 border-black my-8' />
                        )}
                      </>
                    ))}
                    <p className='text-red-600 text-sm'>{required}</p>
                  </>
                </Form>
              )}
            </Formik>
          )}
          {step === 1 && (
            <div className='p-8'>
              <div className='mt-4'>
                <div className='flex flex-col   w-full lg:w-[48%]'>
                  <label className='text-sm' htmlFor='email'>
                    Title <small className='text-red-500'>*</small>
                  </label>
                  <FormInput
                    className='border py-2 border-gray-200  px-4 mt-4 rounded-lg outline-none w-full'
                    onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                      setTitle(text.target.value);
                    }}
                    placeholder='Enter your title'
                    type='text'
                    value={title}
                  />
                </div>
              </div>
              <div className=' py-2 md:py-4' {...getRootProps()}>
                <div className='w-full'>
                  {file?.length > 0 ? (
                    productFiles
                  ) : (
                    <label className='flex flex-col justify-center items-center min-h-[250px] w-full px-4 transition border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none'>
                      <img alt='' className='h-12' src={UPLOAD} />
                      <p className='flex flex-col items-center space-x-2'>
                        <span className='font-medium text-gray-600 mt-4 text-center text-sm'>
                          Click to upload or drag and drop <br />
                          Maxiumum file size 10 MB
                        </span>
                        <a className='h-10 px-6 py-2 mt-4 lg:mt-0  ml-4 font-normal items-center text-black bg-BRAND-200 border border-BRAND-200 rounded-xl '>
                          Browse file
                        </a>
                      </p>
                      <span className='text-[#9CA3AF] text-xs mt-4 '>
                        Supported PDF files only{" "}
                        <span className='text-blue-800 font-bold'>
                          not more than 10 mb
                        </span>
                      </span>
                      <input
                        className='hidden'
                        name='file_upload'
                        type='file'
                        {...getInputProps()}
                      />
                    </label>
                  )}
                </div>
              </div>

              <div className='flex justify-end items-center'>
                {loading ? (
                  <span>processing ... </span>
                ) : (
                  <IconButton onClick={(e) => handleAddDocument(e)}>
                    <FaPlusCircle className='text-brand_blue' />
                    <span className='text-xs ml-2'>
                      {file?.length > 0 && "Add this pdf"}
                    </span>
                  </IconButton>
                )}
              </div>

              <div className=' mx-auto py-8'>
                {getDocuments !== undefined &&
                  getDocuments?.data?.length > 0 && (
                    <h2 className='font-bold mb-4'>Document List</h2>
                  )}
                <ul className='w-full flex justify-between flex-wrap'>
                  {getDocuments !== undefined &&
                    getDocuments?.data?.length > 0 &&
                    getDocuments?.data?.map((document: DocumentTypes) => (
                      <li
                        className='bg-white shadow-md shadow-blue-100 rounded-lg overflow-hidden w-full mb-4 lg:w-[48%]'
                        key={document.id}
                      >
                        <div className='p-3 flex justify-between'>
                          <h3 className='text-sm font-semibold mb-2 capitalize'>
                            {document.title}
                          </h3>

                          <a
                            className='bg-red-600 hover:bg-red-700 text-white text-xs py-1 px-4 rounded-lg inline-block transition duration-300 ease-in-out'
                            onClick={() =>
                              handleDeleteExistingDocument(document.id)
                            }
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            Delete
                          </a>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}

          <div
            className={` flex  ${
              step > 0
                ? " justify-center lg:justify-between"
                : " justify-center"
            } items-center w-full flex-wrap`}
          >
            {step > 0 && (
              <Button
                // isLoading={loading}
                bgColor='bg-black'
                className='py-4 w-52 mt-12 rounded-lg'
                hoverColor=''
                onClick={() => {
                  handleBack();
                }}
                textColor='text-white'
                title='Back'
                type='button'
              />
            )}
            <Button
              bgColor='bg-brand_blue'
              className='py-4  w-52 mt-12 rounded-lg'
              hoverColor=''
              isLoading={isLoading}
              onClick={(e: any) => {
                step > 0 ? handleSubmit() : handleNext();
              }}
              textColor='text-white'
              title={step > 0 ? "Submit" : "Next"}
              type='button'
            />
          </div>
        </div>
        {/* Render other steps content similarly */}
      </div>
    </div>
  );
}

export default AddNew;
