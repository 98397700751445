/* eslint-disable jsx-a11y/alt-text */

import { Fade, Zoom } from "react-awesome-reveal";

import { LAWYER } from "../../../assets";

function TheApp() {
  return (
    <div className='flex justify-center  items-center px-10 py-12 text-black z-30 w-full  max-md:px-5 max-md:max-w-full'>
      <div className='flex flex-col items-center mt-16 mb-16 max-w-full  max-md:my-10'>
        <Zoom triggerOnce={false}>
          <div className='text-4xl font-bold  tracking-wide mx-auto leading-10 text-center  max-md:max-w-full'>
            How it works
          </div>
        </Zoom>
        <Fade direction='left'>
          <div className='  mt-11 text-xl font-medium lg:w-[50%] leading-8 text-center text-neutral-200 max-md:mt-10 w-full  mx-auto' />
        </Fade>
        <div className='  w-full'>
          <div className='w-full flex justify-start gap-10 items-start mt-10 max-md:flex-col'>
            <div className='hidden lg:flex flex-col bg-red-400 w-[50%] max-md:ml-0 max-md:w-full'>
              <img
                alt=''
                className='object-cover  h-[500px] hover:scale-110 delay-300 transition-all ease-in-out duration-1000'
                loading='lazy'
                srcSet={LAWYER}
              />
            </div>

            <div className='flex flex-col ml-5 w-[40%] max-md:ml-0 max-md:w-full '>
              <div className='flex flex-col  py-1.5 my-auto  max-md:max-w-full'>
                <Fade direction='right' duration={1000} triggerOnce={false}>
                  <div className='flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full'>
                    <div className='flex gap-1.5 px-3 py-6 my-auto text-sm  leading-4 text-center  bg-green-200 whitespace-nowrap rounded-full'>
                      <div>Step</div>
                      <div>1</div>
                    </div>
                    <div className='flex flex-col flex-1'>
                      <div className='text-2xl font-bold  leading-7 text-white'>
                        Log In
                      </div>
                      <div className='mt-6 text-lg font-medium  leading-8 text-neutral-100'>
                        Log in to access your dashboard and enjoy the amazing
                        litigation-driven features.
                      </div>
                    </div>
                  </div>
                </Fade>
                <Fade direction='right' duration={2000} triggerOnce={false}>
                  <div className='flex gap-5 justify-between mt-12 max-md:flex-wrap max-md:mt-10 max-md:max-w-full'>
                    <div className='flex gap-1 px-2.5 py-6 my-auto text-sm  leading-4 text-center bg-[#f2f2f2]  rounded-full'>
                      <div>Step</div>
                      <div>2</div>
                    </div>
                    <div className='flex flex-col  flex-1'>
                      <div className='text-2xl font-bold  leading-7 text-white'>
                        Initiate Proceedings
                      </div>

                      <div className='mt-5 text-lg  font-medium leading-8 text-neutral-100'>
                        You can initiate and oversee the legal proceedings of a
                        case from your online dashboard.
                      </div>
                    </div>
                  </div>
                </Fade>
                <Fade direction='right' duration={3000} triggerOnce={false}>
                  <div className='flex gap-5 justify-between mt-12 max-md:flex-wrap max-md:mt-10 max-md:max-w-full '>
                    <div className='flex gap-1 self-start px-2.5 py-6 mt-3.5 text-sm leading-4 text-center  bg-[#f2f2f2] whitespace-nowrap rounded-full'>
                      <div>Step</div>
                      <div>3</div>
                    </div>
                    <div className='flex flex-col flex-1'>
                      <div className='text-2xl font-bold  leading-7 text-white'>
                        Access Features
                      </div>
                      <div className='mt-5 text-lg font-medium  leading-8 text-neutral-200'>
                        Access features such as the assigning of a suit number,
                        process service, virtual hearings, and other platform
                        features
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TheApp;
