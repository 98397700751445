/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable react/no-array-index-key */
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { Zoom } from "react-reveal";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { EMPTY, PDF, UPLOAD } from "../../../assets";
import Button from "../../../components/button";
import DropDownOptions from "../../../components/dropdownBtn/DropDownOptions";
import Input, { FormInput } from "../../../components/form/Input";
import Typography from "../../../components/Typography";
import Modal from "../../../components/UI/modal";
import { useMediaQuery } from "../../../hooks";
import {
  addDocumentCaseApi,
  DeleteDocumentCaseApi,
  getAllDocumentsApi,
  RenameCaseApi,
} from "../../../services/Laywer/Lawyer.service";
import { DocumentTypes } from "../../../services/Laywer/types";
import { HandleOpenDocument } from "../../../utils";

function Documents() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [documentModal, setDocumentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fileUrl, setFileUrl] = useState<string>("");
  const [search, setSearch] = useState("");
  const [data, setData] = useState<any>();
  const isMobileView = useMediaQuery("(max-width: 640px)");
  const caseFolder = useLocation().state;
  const isTabletView = useMediaQuery("(max-width: 840px)");
  const [searchParams] = useSearchParams();
  const idParams = searchParams.get("id");
  const name = searchParams.get("name");
  const deleteDocumentMutation = useMutation(DeleteDocumentCaseApi);
  const [title, setTitle] = useState<any>("");
  const [required, setRequired] = useState<string>("");
  const [file, setFile] = useState<any>([]);

  const { data: getDocuments, refetch } = useQuery(
    ["getDocument", idParams],
    () => getAllDocumentsApi(idParams),
  );

  const handleDeleteExistingDocument = async () => {
    setLoading(true);
    try {
      const response = await deleteDocumentMutation.mutateAsync(data.id);
      if (response) {
        refetch();
        setLoading(false);
        setDeleteModal(false);
        toast.success(response.message, {
          duration: 10_000,
        });
      }
    } catch (error: any) {
      setLoading(false);
      setDeleteModal(false);
      toast.success(error?.response.data?.message, {
        duration: 7000,
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 50_000_000,
    accept: {
      "application/pdf": [".pdf"],
      // "image/jpg": [".jpg"],
      // "image/jpeg": [".jpeg"],
      // "image/png": [".png"],
    },
    onDrop: (acceptedFiles) => {
      setFile(
        acceptedFiles?.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const removeAll = () => {
    setFile([]);
  };
  const productFiles = file?.map((item: any, index: number) => (
    <div className='w-[80%]'>
      <div className='flex justify-start items-center'>
        <img alt='' className='h-10 w-10 ' src={PDF} />

        <p
          className='text-center text-green-600 text-sm font-semibold ml-2'
          key={index}
        >
          {title}
        </p>

        <button
          className='hover:opacity-70 text-white text-xs ml-4 font-bold  rounded-full h-6 w-6 flex items-center justify-center bg-red-600'
          onClick={() => removeAll()}
          type='button'
        >
          X
        </button>
      </div>
    </div>
  ));

  const handleAddDocument = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (file?.length < 1 || file === null) {
      setRequired("required");
      toast.error("PDF is required.", {
        duration: 7000,
      });
      setLoading(false);
    } else {
      try {
        const formData = new FormData();

        formData.append("title", title);
        formData.append("file_path", file[0]);

        const response = await addDocumentCaseApi(formData, idParams);

        if (response) {
          setFile(null);
          setLoading(false);
          toast.success(response.message, { duration: 7000 });
          setRequired("");
          setTitle("");

          refetch();
          setDocumentModal(false);
        }
      } catch (error: any) {
        setLoading(false);
        toast.error(error.response.data.message, { duration: 7000 });
      }
    }
  };

  return (
    <div className='pt-4 px-2 lg:px-8 pb-36 lg:pb-0'>
      <Typography textStyle='text-[20px] font-bold'>
        <>{name} - Documents</>
      </Typography>

      <div className='w-full  pb-20 lg:pb-8'>
        <div className='w-full flex flex-row justify-end lg:justify-between flex-wrap items-start  pt-4 lg:pt-0'>
          <div className=' lg:flex justify-between items-center w-full lg:w-1/3 mb-6 lg:mb-0'>
            <div className='bg-[#2E34D21A]  relative rounded-3xl  py-3 px-2 w-full  my-[2%] flex items-center'>
              <Button
                bgColor='bg-brand_blue'
                className='!text-base !py-1 px-4 absolute right-2 lg:right-3 top-2.5 rounded-xl'
                onClick={() => {}}
                textColor='text-white !text-sm'
                title='Search'
              />
              <input
                className='bg-[transparent] pl-4 border-0 outline-0 w-[100%] placeholder:text-xs  '
                placeholder='Search document'
                type='text'
                value={search}
                // onChange={searchNotificatio}
              />
            </div>
          </div>
          <div className=' flex justify-end w-1/2'>
            <Button
              bgColor='bg-[#2E34D2]'
              className='py-3 w-44 ml-4 !text-sm'
              onClick={() => setDocumentModal(true)}
              textColor='text-white'
              title='Add new document'
            />
          </div>
        </div>

        <div className='mt-8 '>
          <Zoom>
            <div className='mt-4 lg:mt-8 flex justify-start gap-2 lg:gap-[5%] items-end flex-wrap w-full'>
              {getDocuments !== undefined && getDocuments?.data?.length > 0 ? (
                getDocuments?.data?.map((item: DocumentTypes) => (
                  <div className='relative mb-8 flex flex-col px-4 pt-3 font-medium text-black rounded-xl border-solid shadow-md bg-neutral-200 border-t-[1.993px] border-t-black w-[46%] md:w-1/3 lg:w-[21%]'>
                    <div className='flex justify-between text-base w-full'>
                      {isMobileView && isTabletView ? (
                        <div className='w-[70%] text-xs self-start mt-2.5 overflow-ellipsis whitespace-nowrap font-semibold'>
                          {item?.title?.length > 9
                            ? `${item?.title?.slice(0, 9)} ..`
                            : item?.title}
                        </div>
                      ) : (
                        <div className='w-[85%] self-start mt-2.5 overflow-ellipsis whitespace-nowrap font-semibold'>
                          {item?.title?.length > 19
                            ? `${item?.title.slice(0, 20)} ..`
                            : item?.title}
                        </div>
                      )}
                      <div className='w-[30%] lg:w-[15%]'>
                        <DropDownOptions
                          actionTitle='View'
                          handleDelete={() => {
                            setData(item);
                            setDeleteModal(true);
                          }}
                          handleRename={() => {
                            setRenameModal(true);
                            setData(item);
                          }}
                          handleView={() => {
                            setOpen(true);
                            setFileUrl(item.file_path);
                          }}
                          isRename
                        />
                      </div>
                    </div>

                    <div className='text-[10px] lg:text-xs '>
                      May 2, 2023 8:45pm
                    </div>
                    <img
                      alt=''
                      className='self-center mt-3.5 w-full aspect-[1.2]'
                      loading='lazy'
                      srcSet='https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&'
                    />
                  </div>
                ))
              ) : (
                <div className='w-full flex justify-center items-center '>
                  <div className='flex flex-col justify-center items-center h-52'>
                    <img alt='' className='h-32' src={EMPTY} />
                    <p className='text-center text-sm mt-4'>
                      You do not have a document uploaded yet
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Zoom>
        </div>
      </div>
      {HandleOpenDocument(open, setOpen, setFileUrl, fileUrl)}
      <Modal
        close={() => {
          setRenameModal(false);
        }}
        maxWidth='xs'
        open={renameModal}
      >
        <Zoom>
          <p className='text-center font-bold text-xl'>Rename</p>
          <Formik
            initialValues={{
              rename: data?.title || "",
            }}
            // validationSchema={loginSchema}
            onSubmit={async (values, { resetForm }) => {
              try {
                const response = await RenameCaseApi(
                  { title: values.rename },
                  data.id,
                );
                if (response) {
                  toast.success(response.message, {
                    duration: 5000,
                  });
                  setRenameModal(false);
                  refetch();
                  resetForm();
                }
              } catch (error: any) {
                toast.success(error.response.data.message, {
                  duration: 5000,
                });
              }
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form className='flex flex-col pt-3 md:pt-4 px-8 md:px-8 lg:py-6'>
                <div className='flex justify-between items-center flex-wrap '>
                  <div className='flex flex-col pt-2  w-full'>
                    <Input
                      name='rename'
                      placeholder='Enter new name'
                      type='text'
                    />
                  </div>
                </div>
                <div className='flex justify-between items-center w-full'>
                  <Button
                    bgColor='bg-[#CACACA]'
                    className=' mb-2  mt-4  w-40 py-4 rounded-2xl'
                    onClick={() => {
                      setRenameModal(false);
                    }}
                    textColor='text-black'
                    title='Cancel'
                    type='button'
                  />
                  <Button
                    bgColor='bg-brand_indigo'
                    className=' mb-2  mt-4  w-40 py-4 rounded-2xl'
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                    textColor='text-white'
                    title='Submit'
                    type='button'
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Zoom>
      </Modal>

      <Modal
        close={() => {
          setDeleteModal(false);
        }}
        maxWidth='xs'
        open={deleteModal}
      >
        <Zoom>
          <p className='text-center font-bold text-lg'>Delete Documents</p>

          <div className='flex flex-col pt-3 md:pt-4 px-8 md:px-8 lg:py-4'>
            <div className='flex justify-between items-center flex-wrap mb-6'>
              <div className='flex flex-col pt-2  w-full'>
                <p className='text-center text-red-800'>
                  Are you sure you want to delete this document?
                </p>
              </div>
            </div>
            <div className='flex justify-between items-center w-full'>
              <Button
                bgColor='bg-[#CACACA]'
                className=' mb-2  mt-4  w-40 py-4 rounded-2xl'
                onClick={() => {
                  setRenameModal(false);
                }}
                textColor='text-black'
                title='Cancel'
                type='button'
              />
              <Button
                bgColor='bg-brand_indigo'
                className=' mb-2  mt-4  w-40 py-4 rounded-2xl'
                isLoading={loading}
                onClick={handleDeleteExistingDocument}
                textColor='text-white'
                title='Proceed'
                type='button'
              />
            </div>
          </div>
        </Zoom>
      </Modal>
      <Modal
        close={() => {
          setDocumentModal(false);
        }}
        maxWidth='sm'
        open={documentModal}
      >
        <Zoom>
          <div className='py-8  px-8 lg:px-20'>
            <div className='mt-4'>
              <div className='flex flex-col   w-full lg:w-[48%]'>
                <label className='text-sm' htmlFor='email'>
                  Title <small className='text-red-500'>*</small>
                </label>
                <FormInput
                  className='border py-2 border-gray-200  px-4 mt-4 rounded-lg outline-none w-full'
                  onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                    setTitle(text.target.value);
                  }}
                  placeholder='Enter your title'
                  type='text'
                  value={title}
                />
              </div>
            </div>
            <div className=' py-2 md:py-4' {...getRootProps()}>
              <div className='w-full'>
                {file?.length > 0 ? (
                  productFiles
                ) : (
                  <label className='flex flex-col justify-center items-center min-h-[250px] w-full px-4 transition border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none'>
                    <img alt='' className='h-12' src={UPLOAD} />
                    <p className='flex flex-col items-center space-x-2'>
                      <span className='font-medium text-gray-600 mt-4 text-center text-sm'>
                        Click to upload or drag and drop <br />
                        Maxiumum file size 10 MB
                      </span>
                      <a className='h-10 px-6 py-2 mt-4 lg:mt-0  ml-4 font-normal items-center text-black bg-BRAND-200 border border-BRAND-200 rounded-xl '>
                        Browse file
                      </a>
                    </p>
                    <span className='text-[#9CA3AF] text-xs mt-4 '>
                      Supported PDF files only{" "}
                      <span className='text-blue-800 font-bold'>
                        not more than 10 mb
                      </span>
                    </span>
                    <input
                      className='hidden'
                      name='file_upload'
                      type='file'
                      {...getInputProps()}
                    />
                  </label>
                )}
              </div>
            </div>

            <div className='flex  justify-center lg:justify-between items-center w-full flex-wrap'>
              <Button
                // isLoading={loading}
                bgColor='bg-black'
                className='py-4 w-52 mt-12 rounded-lg'
                hoverColor=''
                onClick={() => {
                  setDocumentModal(false);
                }}
                textColor='text-white'
                title='Back'
                type='button'
              />

              <Button
                bgColor='bg-brand_blue'
                className='py-4  w-52 mt-12 rounded-lg'
                hoverColor=''
                isLoading={loading}
                onClick={(e: any) => {
                  handleAddDocument(e);
                }}
                textColor='text-white'
                title='Submit'
                type='button'
              />
            </div>
          </div>
        </Zoom>
      </Modal>
      <Tooltip id='view-tooltip' />
    </div>
  );
}
export default Documents;
