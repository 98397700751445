// import Swiper core and required modules
import { useNavigate } from "react-router-dom";
import { A11y, Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import Button from "../button";

export default () => {
  const navigate = useNavigate();
  return (
    <Swiper
      // install Swiper modules
      autoplay={{
        delay: 10_000,
        disableOnInteraction: true,
      }}
      modules={[Navigation, A11y, Autoplay]}
      slidesPerView={1}
      spaceBetween={0}
    >
      <SwiperSlide>
        <div className='overflow-hidden flex flex-col justify-center items-center min-h-[600px] lg:h-screen  self-center px-16 pt-8 -mt-7 w-full bg-white  max-md:px-5 max-md:max-w-full'>
          <div className='lg:flex gap-5 justify-between w-full'>
            {/* <Fade direction='left' triggerOnce={false}> */}
            <div className=' w-full lg:w-[50%]  '>
              <img
                alt=''
                className='object-cover  inset-0 size-full rounded-2xl'
                // loading='lazy'
                srcSet='https://cdn.builder.io/api/v1/image/assets/TEMP/050b4b0542814ae867db160dd6e5dd2a610133f5ae78608bb6c037ebfcc15963?apiKey=fb715a6bf2504d5fac6b5a06e4022d85&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/050b4b0542814ae867db160dd6e5dd2a610133f5ae78608bb6c037ebfcc15963?apiKey=fb715a6bf2504d5fac6b5a06e4022d85&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/050b4b0542814ae867db160dd6e5dd2a610133f5ae78608bb6c037ebfcc15963?apiKey=fb715a6bf2504d5fac6b5a06e4022d85&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/050b4b0542814ae867db160dd6e5dd2a610133f5ae78608bb6c037ebfcc15963?apiKey=fb715a6bf2504d5fac6b5a06e4022d85&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/050b4b0542814ae867db160dd6e5dd2a610133f5ae78608bb6c037ebfcc15963?apiKey=fb715a6bf2504d5fac6b5a06e4022d85&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/050b4b0542814ae867db160dd6e5dd2a610133f5ae78608bb6c037ebfcc15963?apiKey=fb715a6bf2504d5fac6b5a06e4022d85&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/050b4b0542814ae867db160dd6e5dd2a610133f5ae78608bb6c037ebfcc15963?apiKey=fb715a6bf2504d5fac6b5a06e4022d85&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/050b4b0542814ae867db160dd6e5dd2a610133f5ae78608bb6c037ebfcc15963?apiKey=fb715a6bf2504d5fac6b5a06e4022d85&'
              />
            </div>
            {/* </Fade> */}
            {/* <Fade direction='left' triggerOnce={false}> */}
            <div className='flex flex-col w-full lg:w-[50%] pl-8'>
              <div className='flex flex-col self-stretch my-auto max-md:mt-10 max-w-full'>
                <div className='text-7xl font-bold tracking-normal text-green-950  max-md:max-w-full max-md:text-4xl max-md:leading-[49px] '>
                  Enugu State Virtual Hearing Platform
                </div>
                <div className='mt-20 text-base font-medium tracking-normal text-green-950 leading-6 text-BRAND max-md:max-w-full w-full lg:w-[80%]'>
                  The ESCH is a technology solution aimed at speeding up the
                  litigation process for the judiciary system in Enugu State.
                </div>
              </div>

              <Button
                bgColor='bg-brand_blue'
                className=' mb-2  mt-4  w-56 py-5  !rounded-md '
                onClick={() => navigate("/login")}
                textColor='text-white !text-base'
                title='Get Started'
                type='button'
              />
            </div>
            {/* </Fade> */}
          </div>

          <ul className='circles'>
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
        </div>
      </SwiperSlide>
      {/* <SwiperSlide>
        <div className='overflow-hidden flex justify-center items-center min-h-[600px] lg:h-screen main-bg self-center px-16 pt-8 -mt-7 w-full bg-white  max-md:px-5 max-md:max-w-full'>
          <div className='flex gap-5 flex-col w-full'>
            <Fade
              className='flex flex-col w-full lg:w-[60%] mx-auto '
              direction='left'
              triggerOnce={false}
            >
              <div className='flex flex-col self-stretch my-auto max-md:mt-10 max-w-full'>
                <div className='text-7xl font-bold tracking-normal text-green-100  max-md:max-w-full max-md:text-4xl max-md:leading-[49px] text-center'>
                  Scale up the Legal System with ESCH
                </div>
                <div className='mt-20 text-base font-medium tracking-normal text-green-100 leading-6 text-BRAND max-md:max-w-full text-center w-full lg:w-[50%] mx-auto'>
                  The ESCH is a technology solution aimed at speeding up the
                  litigation process for the judiciary system in Enugu State.
                </div>
              </div>
            </Fade>

            <Button
              bgColor='bg-brand_blue'
              className=' mb-2  mt-4  w-56 py-5  !rounded-md mx-auto'
              onClick={() => navigate("/login")}
              textColor='text-white !text-base'
              title='Get Started'
              type='button'
            />

            <Fade
              className='flex flex-col mx-auto  max-md:ml-0 max-md:w-full'
              direction='right'
              triggerOnce={false}
            />
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className='overflow-hidden flex justify-center items-center min-h-[600px] lg:h-screen main-bg self-center px-16 pt-8 -mt-7 w-full bg-white  max-md:px-5 max-md:max-w-full'>
          <div className='flex gap-5 flex-col w-full'>
            <Fade
              className='flex flex-col w-full lg:w-[60%] mx-auto '
              direction='left'
              triggerOnce={false}
            >
              <div className='flex flex-col self-stretch my-auto max-md:mt-10 max-w-full'>
                <div className='text-7xl font-bold tracking-normal text-green-100  max-md:max-w-full max-md:text-4xl max-md:leading-[49px] text-center'>
                  Scale up the Legal System with ESCH
                </div>
                <div className='mt-20 text-base font-medium tracking-normal text-green-100 leading-6 text-BRAND max-md:max-w-full text-center w-full lg:w-[50%] mx-auto'>
                  The ESCH is a technology solution aimed at speeding up the
                  litigation process for the judiciary system in Enugu State.
                </div>
              </div>
            </Fade>

            <Button
              bgColor='bg-brand_blue'
              className=' mb-2  mt-4  w-56 py-5  !rounded-md mx-auto'
              onClick={() => navigate("/login")}
              textColor='text-white !text-base'
              title='Get Started'
              type='button'
            />

            <Fade
              className='flex flex-col mx-auto  max-md:ml-0 max-md:w-full'
              direction='right'
              triggerOnce={false}
            />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='overflow-hidden flex justify-center items-center min-h-[600px] lg:h-screen main-bg self-center px-16 pt-8 -mt-7 w-full bg-white  max-md:px-5 max-md:max-w-full'>
          <div className='flex gap-5 flex-col w-full'>
            <Fade
              className='flex flex-col w-full lg:w-[60%] mx-auto '
              direction='left'
              triggerOnce={false}
            >
              <div className='flex flex-col self-stretch my-auto max-md:mt-10 max-w-full'>
                <div className='text-7xl font-bold tracking-normal text-green-100  max-md:max-w-full max-md:text-4xl max-md:leading-[49px] text-center'>
                  Scale up the Legal System with ESCH
                </div>
                <div className='mt-20 text-base font-medium tracking-normal text-green-100 leading-6 text-BRAND max-md:max-w-full text-center w-full lg:w-[50%] mx-auto'>
                  The ESCH is a technology solution aimed at speeding up the
                  litigation process for the judiciary system in Enugu State.
                </div>
              </div>
            </Fade>

            <Button
              bgColor='bg-brand_blue'
              className=' mb-2  mt-4  w-56 py-5  !rounded-md mx-auto'
              onClick={() => navigate("/login")}
              textColor='text-white !text-base'
              title='Get Started'
              type='button'
            />

            <Fade
              className='flex flex-col mx-auto  max-md:ml-0 max-md:w-full'
              direction='right'
              triggerOnce={false}
            />
          </div>
        </div>
      </SwiperSlide> */}
    </Swiper>
  );
};
