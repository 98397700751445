/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import moment from "moment";
import { Fade } from "react-awesome-reveal";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import { NavbarWithMegaMenu } from "../../components/Navbar";
import ScrollContainer from "../../components/ScrollContainer";
import Slider from "../../components/Slider";
import Footer from "../../components/UI/Footer";
import { GetNews } from "../../services/Admin/service";
import Features from "./components/Features";
import Welcome from "./components/Welcome";

function Home() {
  const { data } = useQuery("posts", GetNews);

  return (
    <ScrollContainer>
      <NavbarWithMegaMenu />
      <div className='flex flex-col'>
        <div id=''>
          <Slider />
        </div>
        <div className='z-30' id='the-app'>
          <Welcome />
        </div>

        <Features />

        <section className='py-6 sm:py-12 bg-white'>
          <div className='container p-6 mx-auto space-y-8'>
            <Fade triggerOnce={false}>
              <div className='space-y-2 text-center'>
                <h2 className='text-3xl font-bold'>News and Events</h2>
                <p className='font-serif text-sm dark:text-gray-600'>
                  Get the latest happenings on our news and events
                </p>
              </div>
            </Fade>
            <div className='grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-4'>
              {data?.length > 0 &&
                data?.map((post: any, index: number) => (
                  <>
                    {index < 4 && (
                      <article className='flex flex-col ' key={post?.id}>
                        <Link to={`/news-and-events/details?id=${post.id}`}>
                          <img
                            alt=''
                            className='object-cover w-full h-52 '
                            src={post?.image}
                          />
                        </Link>
                        <div className='flex flex-col flex-1 p-6'>
                          <a
                            aria-label='Te nulla oportere reprimique his dolorum'
                            href='#'
                            rel='noopener noreferrer'
                          />
                          <Link
                            className=' hover:text-green-900 '
                            to='/news-and-events'
                          >
                            news
                          </Link>
                          <h3 className='flex-1 py-2 text-lg font-semibold leading-snug'>
                            {post?.title}
                          </h3>
                          <div className='flex flex-wrap justify-between pt-3 space-x-2 text-xs dark:text-gray-600'>
                            <span>
                              {" "}
                              {moment(post?.created_at).format("DD MMM")}
                            </span>
                          </div>
                        </div>
                      </article>
                    )}
                  </>
                ))}
            </div>
            <div className='flex justify-end items-start'>
              <Link
                className='border-2 border-green-900 px-2 py-1 rounded hover:scale-110 transition-all ease-in-out duration-500'
                to='/news-and-events'
              >
                Read more
              </Link>
            </div>
          </div>
        </section>
        {/* <Testimonials /> */}

        <Footer />
      </div>
    </ScrollContainer>
  );
}

export default Home;
