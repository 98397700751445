import toast from "react-hot-toast";

import { API } from "../../config";
import { setGlobalLoading } from "../../states/slices/authReducer";

export const updatePost = async (payload: any) => {
  const response = await API.post(
    `admin/posts/${payload.id}/update`,
    payload.formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response?.data;
};
export const CreatePost = async (payload: any) => {
  const response = await API.post(
    "admin/posts",
    payload,

    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response?.data;
};
export const DeletePost = async (payload: any) => {
  const response = await API.post("admin/posts/:post_id", payload);
  return response?.data;
};
export const GetNews = async () => {
  const response = await API.get("posts");
  return response?.data?.data;
};
export const GetNewsDetails = async (post_id: string | null) => {
  const response = await API.get(`posts/${post_id}`);
  return response?.data?.data;
};
export const DeletePostApi = async (
  data: string,
  dispatch: any,
  refetch: any,
) => {
  dispatch(setGlobalLoading(true));

  try {
    const response = await API.delete(`admin/posts/${data}`);
    if (response) {
      dispatch(setGlobalLoading(false));
      toast.success(response.data.message, {
        duration: 10_000,
      });
      refetch();
    }
  } catch (error: any) {
    dispatch(setGlobalLoading(false));
    toast.error(error.response.data.message, {
      duration: 10_000,
    });
  }
};
