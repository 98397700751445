/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Transition } from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { LOGOUT } from "../../assets";
import { useAppDispatch, useAppSelector, useMediaQuery } from "../../hooks";
import { selectRole } from "../../states/slices/authReducer";
import {
  resetDoc as resetDocument,
  resetValue,
  updateCase,
} from "../../states/slices/partyInfo.slice";
import { UserType } from "../../utils/Enums";
import Button from "../button";
import Typography from "../Typography";
import { adminSidebar, LawyerSidebar, SidebarDataTypes } from "./SidebarData";

interface SidebarProps {
  open: boolean;
  onClose: () => void;
  // setOpen: any;
}

export const Sidebar = (props: SidebarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const role = useAppSelector(selectRole);
  const dispatch = useAppDispatch();

  return (
    <Transition
      as='div'
      className='flex-none h-full w-60 lg:w-[18vw] fixed lg:static z-30 lg:z-0 bg-brand_indigo'
      enter='transition-all ease-in duration-500'
      enterFrom='transform -translate-x-full'
      enterTo='transform -translate-x-0'
      leave='transition-all ease-out duration-500'
      leaveFrom='transform -translate-x-0'
      leaveTo='transform -translate-x-full'
      show={props.open}
    >
      <div className='h-full bg-deep-surface  relative '>
        <div className={`w-full py-6 px-2 `}>
          <Link
            to={
              role === UserType.LAWYER
                ? "/lawyer/profile"
                : role === UserType.REGISTRAR_1
                  ? "/registrar-1/profile"
                  : role === UserType.REGISTRAR_2
                    ? "/registrar-2/profile"
                    : role === UserType.REGISTRAR_3
                      ? "/registrar-3/profile"
                      : role === UserType.PRESIDING_JUDGE
                        ? "/presiding-judge/profile"
                        : role === UserType.PRESIDING_MAGISTRATE
                          ? "/presiding-magistrate/profile"
                          : role === UserType.JUDGE
                            ? "/dashboard/profile"
                            : role === UserType.ADMIN
                              ? "/admin/profile"
                              : role === UserType.NON_LAWYER
                                ? "/non-lawyer/profile"
                                : "/"
            }
          >
            <p className='font-bold text-white text-2xl ml-4'>iCase</p>
          </Link>
        </div>

        <div className='flex flex-col justify-between h-[89vh] pb-10 pr-5 pt-12 '>
          <div className=''>
            <ul>
              <Tabs
                {...{
                  data: role === UserType.ADMIN ? adminSidebar : LawyerSidebar,
                  className: "hover:font-medium",
                  onClose: props.onClose,
                  active: (item: SidebarDataTypes) =>
                    location.pathname === item.path,
                }}
              />
            </ul>
          </div>

          <div className='flex flex-col justify-start items-start pl-5'>
            <Button
              bgColor='bg-transparent'
              className=' text-white '
              hoverColor=''
              iconClassName='h-4 w-4'
              icons={LOGOUT}
              onClick={() => {
                dispatch(resetDocument());
                dispatch(resetValue());
                dispatch(updateCase(null));

                navigate("/login");
              }}
              textColor='text-black !text-base'
              title='Logout'
            />
          </div>
        </div>
      </div>
    </Transition>
  );
};

interface TabsProps {
  data: SidebarDataTypes[] | null;
  active: any;
  className?: string;
  containerClassName?: string;
  onClose: () => void;
}

const Tabs = ({
  data,
  active,
  className,
  containerClassName,
  onClose,
}: TabsProps) => {
  const isMobileView = useMediaQuery("(max-width: 640px)");
  const isTabletView = useMediaQuery("(max-width: 840px)");

  return (
    <>
      {data !== null &&
        data.map((item) => (
          <li
            className={`${containerClassName} mb-2`}
            key={item.id}
            onClick={isMobileView || isTabletView ? onClose : () => {}}
          >
            <Link className=' ' to={item.path}>
              <div
                className={` flex  w-full items-center py-2 px-5 ${className} ${
                  active(item)
                    ? "border-r border-[#4674e5] rounded-r-lg bg-black font-bold"
                    : "font-medium "
                } text-white`}
              >
                {item.icon}
                <Typography textStyle='text-base'>{item.title}</Typography>
              </div>
            </Link>
          </li>
        ))}
    </>
  );
};
