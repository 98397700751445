/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Skeleton } from "@mui/material";
import { Form, Formik } from "formik";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Zoom } from "react-reveal";
import { useNavigate } from "react-router-dom";

import { EMPTY } from "../../../assets";
import Button from "../../../components/button";
import Input from "../../../components/form/Input";
import { POST_COLUMNS } from "../../../components/table/columns/Lawyer";
import { TableComponent } from "../../../components/table/Table";
import Typography from "../../../components/Typography";
import Modal from "../../../components/UI/modal";
import { useAppSelector } from "../../../hooks";
import {
  DeletePostApi,
  GetNews,
  updatePost,
} from "../../../services/Admin/service";
import {
  getSelectedCase,
  setToggle_2,
  setToggle_3,
  toggleState_2,
  toggleState_3,
} from "../../../states/slices/partyInfo.slice";

const Loader = lazy(() => import("../../../components/Loader/index"));

export default function () {
  const [joinView, setJoinView] = useState(1);
  const dispatch = useDispatch();
  const toggle2 = useAppSelector(toggleState_2);
  const toggle3 = useAppSelector(toggleState_3);
  const [page, setPage] = useState(1);
  const { data, refetch } = useQuery("posts", GetNews);
  const [file, setFile] = useState<any>([]);
  const createPostmutation = useMutation(updatePost);
  const navigate = useNavigate();
  const Case = useAppSelector(getSelectedCase);
  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  useEffect(() => {
    refetch();
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 50_000_000,
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      // "image/webp": [".webp"],
    },
    onDrop: (acceptedFiles) => {
      setFile(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });
  const removeAll = () => {
    setFile([]);
  };
  const productFiles = file.map((item: any, index: number) => (
    <div className='w-full border border-gray-300 rounded-lg flex justify-between items-center px-4 py-2 mb-2'>
      <div className='flex justify-start items-center'>
        <img alt='' className='h-8 w-8 ' src={file[0]?.preview} />
      </div>
      <div className='flex justify-between items-center'>
        <div className='flex justify-end'>
          <button
            className='w-6 h-6 flex justify-center items-center rounded-full hover:opacity-70 text-white text-sm   bg-red-600'
            onClick={() => removeAll()}
            type='button'
          >
            x
          </button>
        </div>
      </div>
    </div>
  ));

  const handleNext = () => {
    setPage(page + 1);
  };

  return (
    <Suspense fallback={<Loader />}>
      <div className='pt-4 px-2 lg:px-8 pb-36 lg:pb-0'>
        <Typography textStyle='text-[20px] font-bold'>All Posts</Typography>
        <div className='w-full  pb-20 lg:pb-8'>
          <div className='w-full flex flex-row justify-end lg:justify-between flex-wrap items-start  pt-4 lg:pt-0' />

          <div className='mt-8 '>
            <div className=' w-full lg:mb-0'>
              <Zoom>
                {data !== undefined && data?.length > 0 ? (
                  <div className=' border-b border-t-2 rounded-t-xl border-t-black border-[#F0F0F0] rounded-b-xl relative'>
                    <TableComponent
                      className='rounded-none rounded-t-0 '
                      COLUMNS={POST_COLUMNS}
                      DATA={data}
                      page={page}
                      setPage={setPage}
                    />
                  </div>
                ) : data !== undefined && data?.length < 1 ? (
                  <div className='text-center text-xs w-full'>
                    <div className='flex flex-col justify-center items-center h-52'>
                      <img alt='' className='h-32' src={EMPTY} />
                      <p className='text-center text-sm mt-4'>
                        You have not created any case folder
                      </p>
                    </div>

                    {page > 1 && (
                      <div className='flex justify-end items-center w-full px-2 lg:px-4 py-2'>
                        <div className='flex justify-center items-center text-xs rounded-sm py-1  w-full '>
                          {page > 1 && (
                            <button
                              className=' cursor-pointer px-4 py-2 border-2 w-40 border-brand rounded-lg text-brand'
                              onClick={() => handlePrevious()}
                              type='button'
                              // disabled={!canPreviousPage}
                            >
                              Previous
                            </button>
                          )}

                          <span className='mx-4 text-xs'>
                            Page <strong>{page}</strong>{" "}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='w-full mb-3 px-8 flex justify-between items-center'>
                    <Skeleton
                      className='w-[50px] rounded-full mb-4'
                      height={50}
                      variant='rectangular'
                    />
                    <div className='w-[80%]'>
                      <Skeleton
                        className='w-full mb-4'
                        height={20}
                        variant='rectangular'
                      />
                      <Skeleton
                        className='w-[80%] mb-4'
                        height={20}
                        variant='rectangular'
                      />
                    </div>
                  </div>
                )}
              </Zoom>
            </div>
          </div>
        </div>
      </div>
      <Modal
        close={() => {
          dispatch(setToggle_2(false));
        }}
        maxWidth='md'
        open={toggle2}
      >
        <Zoom>
          <p className='font-bold text-xl text-center my-6'>Create News Post</p>
          <Formik
            initialValues={{
              title: Case?.title ?? "",
              blog: Case?.body ?? "",
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                const formData = new FormData();
                formData.append("title", values.title);
                formData.append("body", values.blog);
                if (file.length > 0) {
                  formData.append("image", file[0]);
                }

                const payload = {
                  id: Case.id,
                  formData,
                };
                const response = await createPostmutation.mutateAsync(payload);

                if (response) {
                  toast.success(response.message, {
                    duration: 7000,
                  });
                  resetForm();
                  file.length = 0;
                  navigate("/admin/all-posts");
                }
              } catch (error: any) {
                toast.error(error?.response?.data?.message, {
                  duration: 10_000,
                });
              }
            }}
          >
            {({
              handleSubmit,
              setFieldValue,
              values,
              handleChange,
              touched,
              errors,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form className='flex flex-col pt-3 md:pt-4 px-4  lg:pt-6 pb-16 lg:px-24'>
                <>
                  <div className='w-full  mx-auto'>
                    <div className='flex justify-start items-center flex-wrap'>
                      <div className='flex flex-col   w-full mb-2 lg:mb-6'>
                        <label className='mb-3 flex justify-start items-center'>
                          Post Title
                        </label>
                        <Input
                          name='title'
                          placeholder='Enter the post title'
                          type='text'
                        />
                      </div>

                      <div className='flex flex-col w-full mb-2 lg:mb-6'>
                        <label className='mb-3 flex justify-start items-center'>
                          Post
                        </label>
                        <textarea
                          className='border-gray-200 appearance-none border rounded-md h-20 w-full resize-none flex justify-start p-4 text-sm'
                          name='blog'
                          onChange={handleChange}
                          placeholder='Enter post texts'
                          value={values.blog}
                        />
                      </div>
                      <div className='flex flex-col w-full mb-2 lg:mb-6'>
                        <label className='mb-3 flex justify-start items-center'>
                          Upload Image
                        </label>
                        <div className='flex flex-col  w-full lg:w-[48%]'>
                          <div className='' {...getRootProps()}>
                            <div className='w-full'>
                              {file.length > 0 ? (
                                productFiles
                              ) : (
                                <label className='flex  justify-start items-center h-12 w-full px-2 transition border border-gray-300 rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none'>
                                  <p className='flex justify-between items-center w-full'>
                                    <img
                                      alt=''
                                      className='h-8'
                                      src={Case?.image}
                                    />
                                    <a className='h-8 px-6  bg-[#CACACA] pt-1 pb-2 self-end  font-normal items-center text-xs text-black bg-BRAND-200 border border-BRAND-200 rounded-md '>
                                      upload
                                    </a>
                                  </p>

                                  <input
                                    className='hidden'
                                    name='file_upload'
                                    type='file'
                                    {...getInputProps()}
                                  />
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Button
                    bgColor='bg-brand_indigo'
                    className='py-4  w-full rounded-lg'
                    hoverColor=''
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                    textColor='text-white'
                    title=' Submit'
                    type='button'
                  />
                </>
              </Form>
            )}
          </Formik>
        </Zoom>
      </Modal>
      <Modal
        close={() => {
          dispatch(setToggle_3(false));
        }}
        maxWidth='xs'
        open={toggle3}
      >
        <Zoom>
          <div className='px-12'>
            <div className='self-center text-lg my-8 font-semibold text-center'>
              Confirm you want to delete this post
            </div>

            <div className='flex justify-center w-full'>
              <Button
                bgColor='bg-[#98C3F0]'
                className=' mb-2  mt-4 w-36 py-4 rounded-2xl'
                onClick={() => dispatch(setToggle_3(false))}
                textColor='text-black'
                title='Cancel'
                type='button'
              />
              <Button
                bgColor='bg-brand_blue'
                className=' mb-2  mt-4 ml-4 w-36 py-4 rounded-2xl'
                onClick={() => {
                  DeletePostApi(Case?.id, dispatch, refetch);
                  dispatch(setToggle_3(false));
                }}
                textColor='text-white'
                title='Submit'
                type='button'
              />
            </div>
          </div>
        </Zoom>
      </Modal>
    </Suspense>
  );
}
