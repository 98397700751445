/* eslint-disable react/jsx-no-useless-fragment */

import React, { useMemo } from "react";
import { Zoom } from "react-reveal";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

interface TableTypes {
  isNotPaginanated?: any;
  COLUMNS: any;
  DATA: any;
  page?: any;
  setPage?: any;
  className: any;
}

export const TableComponent = ({
  COLUMNS,
  DATA,
  setPage,
  isNotPaginanated,
  page,
  className,
}: TableTypes) => {
  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => DATA, [DATA]);

  const tableHooks = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const { getTableProps, getTableBodyProps, headerGroups, state, prepareRow } =
    tableHooks;

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    setPage(page + 1);
  };
  return (
    <Zoom>
      <div className='w-full overflow-scroll bg-transparent'>
        <div
          className={`w-full  border-b border-t-2 border-x border-t-black rounded-t-xl overflow-scroll text-sm lg:rounded-xl ${className}`}
        >
          <table
            className='w-full table  px-8  bg-white !text-dark  border-gray-300'
            {...getTableProps()}
          >
            <thead className='w-full bg-brand '>
              {headerGroups.map((headerGroup: any) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className='border-b border-b-black'
                >
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className=' whitespace-nowrap text-left pl-6 pr-3 py-4  text-sm font-semibold'
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className='px-8'>
              {tableHooks?.page?.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => (
                      <td
                        className='pl-6 pr-3 py-4 border-0  text-xs'
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {isNotPaginanated ? (
          <></>
        ) : (
          <div className='flex justify-end items-center w-full px-2 lg:px-4 py-2'>
            <div className='flex justify-center items-center text-xs rounded-sm py-1  w-full '>
              {page > 1 && (
                <button
                  className=' cursor-pointer px-4 py-2 border-2 w-40 border-brand rounded-lg text-brand'
                  onClick={() => handlePrevious()}
                  type='button'
                >
                  Previous
                </button>
              )}

              <span className='mx-4 text-xs'>
                Page <strong>{page}</strong>{" "}
              </span>
              {data?.length > 14 && (
                <button
                  className='cursor-pointer px-4 py-2 w-40 border-2 border-brand rounded-lg text-brand'
                  onClick={() => handleNext()}
                  type='button'
                >
                  Next
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </Zoom>
  );
};
