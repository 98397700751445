/* eslint-disable jsx-a11y/alt-text */

import { NavbarWithMegaMenu } from "../../components/Navbar";
import ScrollContainer from "../../components/ScrollContainer";
import ProductHero from "../../components/Slider/ProductHero";
import Footer from "../../components/UI/Footer";
import TheApp from "./components/TheApp";

function VirtualHearing() {
  return (
    <ScrollContainer>
      <div className='flex flex-col'>
        <NavbarWithMegaMenu />
        <div id=''>
          <ProductHero />
        </div>
        <div className='z-30' id='the-app'>
          <TheApp />
        </div>
        {/* <div id='faq'>
          <FAQ />
        </div> */}
        <Footer />
      </div>
    </ScrollContainer>
  );
}

export default VirtualHearing;
