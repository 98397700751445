/* eslint-disable @typescript-eslint/no-shadow */
import { Skeleton } from "@mui/material";
import { useEffect } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { useQuery } from "react-query";

import { EMPTY, FOLDER_BG, USER_BG } from "../../../assets";
import { CASE_CAUSELIST_COLUMNS } from "../../../components/table/columns/Lawyer";
import { TableComponent } from "../../../components/table/Table";
import Typography from "../../../components/Typography";
import { useMediaQuery } from "../../../hooks";
import { getDashboardAnalytics } from "../../../services/Laywer/Lawyer.service";

function Dashboard() {
  const isMobileView = useMediaQuery("(max-width: 640px)");
  const isTabletView = useMediaQuery("(max-width: 840px)");

  const {
    data: analytics,
    isLoading,
    refetch,
  } = useQuery("analytcs", getDashboardAnalytics);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className='pt-4 lg:px-8'>
      <Typography textStyle='text-[20px] font-medium'>My Dashboard</Typography>
      <Fade direction='left'>
        <div className='mt-4 lg:mt-8 flex justify-start gap-5 flex-wrap w-full'>
          <div className='relative mb-8 flex flex-col px-4 py-3 h-32 font-medium text-black rounded-xl border-solid shadow-md bg-white border-t-[0.3rem] border-t-[#D19700] w-full  lg:w-[32%]'>
            <div className='flex justify-between text-base w-full flex-wrap'>
              <img alt='' className='h-8 w-8 mr-4' src={USER_BG} />
              {isMobileView && isTabletView ? (
                <div className='w-[70%]  self-start  overflow-ellipsis  text-sm font-semibold'>
                  Number of Physical Court Hearings
                  <div className='text-lg'>
                    {analytics?.physical_count ?? 0}
                  </div>
                </div>
              ) : (
                <div className='w-[85%] text-base self-start overflow-ellipsis whitespace-nowrap font-semibold'>
                  Number of Physical Court Hearings
                  <div className='text-2xl mt-4'>
                    {analytics?.physical_count ?? 0}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='relative mb-8 flex flex-col px-4 py-3 h-32 font-medium text-black rounded-xl border-solid shadow-md bg-white border-t-[0.3rem] border-t-brand_blue w-full  lg:w-[32%]'>
            <div className='flex justify-between text-base w-full '>
              <img alt='' className='h-8 w-8 mr-4' src={FOLDER_BG} />
              {isMobileView && isTabletView ? (
                <div className='w-[70%] self-start  overflow-ellipsis text-sm font-semibold'>
                  Number of Virtual Court Hearings
                  <div className='text-lg'>
                    {" "}
                    {analytics?.virtual_count ?? 0}
                  </div>
                </div>
              ) : (
                <div className='w-[85%] text-base self-start overflow-ellipsis whitespace-nowrap font-semibold'>
                  Number of Virtual Court Hearings
                  <div className='text-2xl mt-4'>
                    {analytics?.virtual_count ?? 0}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='relative mb-8 flex flex-col px-4 py-3 h-32 font-medium text-black rounded-xl border-solid shadow-md bg-white border-t-[0.3rem] border-t-[#D19700] w-full  lg:w-[32%]'>
            <div className='flex justify-between text-base w-full flex-wrap'>
              <img alt='' className='h-8 w-8 mr-4' src={USER_BG} />
              {isMobileView && isTabletView ? (
                <div className='w-[70%]  self-start  overflow-ellipsis  text-sm font-semibold'>
                  Total Court Hearings
                  <div className='text-lg'>{analytics?.total_count ?? 0}</div>
                </div>
              ) : (
                <div className='w-[85%] text-base self-start overflow-ellipsis whitespace-nowrap font-semibold'>
                  Total Court Hearings
                  <div className='text-2xl mt-4'>
                    {analytics?.total_count ?? 0}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fade>

      <div className='mt-8 '>
        <div className=' w-full lg:mb-0'>
          <Zoom>
            {analytics?.cause_lists !== undefined &&
            analytics?.cause_lists?.length > 0 ? (
              <div className=' border-b border-t-2 rounded-t-xl border-t-black border-[#F0F0F0] rounded-b-xl relative'>
                <TableComponent
                  className='rounded-none rounded-t-0 '
                  COLUMNS={CASE_CAUSELIST_COLUMNS}
                  DATA={analytics?.cause_lists}
                  isNotPaginanated
                />
              </div>
            ) : analytics?.cause_lists !== undefined &&
              analytics?.cause_lists?.length < 1 ? (
              <div className='text-center text-xs w-full'>
                <div className='flex flex-col justify-center items-center h-52'>
                  <img alt='' className='h-32' src={EMPTY} />
                  <p className='text-center text-sm mt-4'>
                    You do not have any causelist
                  </p>
                </div>
              </div>
            ) : (
              <div className='w-full mb-3 px-8 flex justify-between items-center'>
                <Skeleton
                  className='w-[50px] rounded-full mb-4'
                  height={50}
                  variant='rectangular'
                />
                <div className='w-[80%]'>
                  <Skeleton
                    className='w-full mb-4'
                    height={20}
                    variant='rectangular'
                  />
                  <Skeleton
                    className='w-[80%] mb-4'
                    height={20}
                    variant='rectangular'
                  />
                </div>
              </div>
            )}
          </Zoom>
        </div>
      </div>
    </div>
  );
}
// Number  of users
export default Dashboard;
