/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable unicorn/no-array-for-each */
import { Form, Formik } from "formik";
import React from "react";
import { Zoom } from "react-awesome-reveal";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";

import Button from "../../../components/button";
import Input from "../../../components/form/Input";
import { TableComponent } from "../../../components/table/Table";
import { useAppDispatch } from "../../../hooks";
import { AddCauselist } from "../../../services/Laywer/Lawyer.service";
import {
  addValue,
  getPartyInfo,
  removeValue,
  resetDoc as resetDocument,
  resetValue,
} from "../../../states/slices/partyInfo.slice";

function ScheduleHearing() {
  const hearingMutation = useMutation(AddCauselist);

  const dispatch = useAppDispatch();
  const partyInfo = useSelector(getPartyInfo);

  const handleParties = (values: any, setFieldValue: any) => {
    dispatch(
      addValue({
        name: values.name,
        email: values.email,
        phone_number: values.phone,
      }),
    );

    setFieldValue("name", "");
    setFieldValue("email", "");
    setFieldValue("phone", "");
  };
  const handleDelete = (item: any) => {
    // console.log(item);

    dispatch(removeValue(item));
  };
  const partyColumn = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone Number",
      accessor: "phone_number",
    },
    {
      Header: "Action",
      Cell: ({ cell: { row } }: any) => (
        <button
          className='px-2 py-1 hover:opacity-70 bg-white text-xs border-2 border-red-800  rounded-md text-red-700'
          onClick={() => handleDelete(row.original)}
          type='button'
        >
          Delete
        </button>
      ),
    },
  ];

  return (
    <div className='w-full lg:w-[80%] mx-auto'>
      <Zoom>
        <p className='font-bold text-xl text-center my-6'>Schedule Hearing</p>
        <Formik
          initialValues={{
            title: "",
            suitnumber: "",
            name: "",
            classification: "",
            type: "",
            status: "",
            email: "",
            phone: "",
            description: "",
            scheduled_at: "",
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              // const nameArray = values.name
              //   .split(",")
              //   .map((item) => item.trim());

              // const emailArray = values.email
              //   .split(",")
              //   .map((email) => email.trim());
              // const phoneArray = values.phone
              //   .split(",")
              //   .map((phone) => phone.trim());
              const updatedValues = {
                ...values,

                // name: nameArray,
                // email: emailArray,
              };

              const formData = new FormData();
              formData.append("case_title", values.title);
              formData.append("suit_number", values.suitnumber);
              formData.append("type", values.type);
              formData.append("status", values.status);

              formData.append("sitting_at", values.scheduled_at);
              formData.append("description", values.description);
              formData.append("classification", values.classification);

              partyInfo?.forEach((item: any, index: number) => {
                formData.append(`parties[${index}][name]`, item.name);
                formData.append(`parties[${index}][email]`, item.email);
                formData.append(
                  `parties[${index}][phone_number]`,
                  item.phone_number,
                );
              });

              const response = await hearingMutation.mutateAsync(formData);

              if (response) {
                toast.success(response.message, {
                  duration: 7000,
                });
                resetForm();
                dispatch(resetDocument());
                dispatch(resetValue());
              }
            } catch (error: any) {
              toast.error(error?.response?.data?.message, {
                duration: 10_000,
              });
            }
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            values,
            handleChange,
            touched,
            errors,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form className='flex flex-col pt-3 md:pt-4 px-4  lg:pt-6 pb-16 lg:px-24'>
              <>
                <div className='w-full  mx-auto'>
                  <div className='flex justify-start items-center flex-wrap'>
                    <div className='flex flex-col   w-full mb-2 lg:mb-6'>
                      <label className='mb-3 flex justify-start items-center'>
                        Case Title
                      </label>
                      <Input
                        name='title'
                        placeholder='Enter the case title'
                        type='text'
                      />
                    </div>
                    <div className='flex flex-col   w-full mb-2 lg:mb-6'>
                      <label className='mb-3 flex justify-start items-center'>
                        Suit Number
                      </label>
                      <Input
                        name='suitnumber'
                        placeholder='Enter the suit number'
                        type='text'
                      />
                    </div>
                    <div className='flex flex-col w-full mb-2 lg:mb-6'>
                      <label className='mb-3 flex justify-start items-center'>
                        Brief description of case
                      </label>
                      <textarea
                        className='border-gray-200 appearance-none border rounded-md h-20 w-full resize-none flex justify-start p-4 text-sm'
                        name='description'
                        onChange={handleChange}
                        value={values.description}
                      />
                    </div>

                    <div className='flex flex-col   w-full mb-2 lg:mb-6'>
                      <label className='mb-3 flex justify-start items-center'>
                        Classification
                      </label>
                      <Input
                        name='classification'
                        placeholder='Enter the classification'
                        type='text'
                      />
                    </div>

                    <div className='flex flex-col   w-full mb-2 lg:mb-6'>
                      <label className='mb-3 flex justify-start items-center'>
                        Status
                      </label>
                      <select
                        className={` border-gray-200 appearance-none border rounded-md  w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
                        name='status'
                        onChange={handleChange}
                        value={values.status}
                      >
                        <option className='text-gray-200' value='' />
                        <option value='MENTION'>MENTION</option>
                        <option value='PART HEARD'>MOTION</option>
                        <option value='MOTION'>PART_HEAD</option>
                        <option value='MOTION'>HEARING</option>
                        <option value='MOTION'>TRIAL</option>
                        <option value='MOTION'>RULING</option>
                        <option value='MOTION'>JUDGEMENT</option>
                        <option value='MOTION'>ADOPTION</option>
                        <option value='MOTION'>FURTHER_DIRECTIVE</option>
                        <option value='MOTION'>CONTINUATION_OF_TRIAL</option>
                        <option value='MOTION'>ADOPTION</option>
                      </select>
                      {touched.type && errors.type && (
                        <span className='text-red-500 text-sm'>
                          {errors.status}
                        </span>
                      )}
                    </div>

                    <div className='mb-6'>Parties Information</div>
                    <div className='w-full lg:flex justify-between'>
                      <div className='flex flex-col   w-full lg:w-[32%] mb-2 lg:mb-6'>
                        <label className='mb-3 flex justify-start items-center'>
                          Name
                        </label>
                        <Input
                          name='name'
                          placeholder='Enter name'
                          type='text'
                        />
                      </div>
                      <div className='flex flex-col   w-full lg:w-[32%] mb-2 lg:mb-6'>
                        <label className='mb-3 flex justify-start items-center'>
                          Email
                        </label>
                        <Input
                          name='email'
                          placeholder='Enter email'
                          type='text'
                        />
                      </div>
                      <div className='flex flex-col   w-full lg:w-[32%] mb-2 lg:mb-6'>
                        <label className='mb-3 flex justify-start items-center'>
                          Phone Number
                        </label>
                        <Input
                          name='phone'
                          placeholder='Enter phone number'
                          type='text'
                        />
                      </div>
                    </div>
                    <div className='w-full flex justify-end'>
                      <a
                        className='border-2 hover:scale-110 transition-all ease-out duration-700 cursor-pointer border-green-950 rounded-lg py-2 px-4 self-end'
                        onClick={() => handleParties(values, setFieldValue)}
                      >
                        Add party
                      </a>
                    </div>

                    {partyInfo !== undefined && partyInfo?.length > 0 && (
                      <div className='  border-t-black border-[#F0F0F0] my-6  w-full rounded-b-xl relative '>
                        <TableComponent
                          className='rounded-none rounded-t-0 '
                          COLUMNS={partyColumn}
                          DATA={partyInfo}
                          isNotPaginanated
                        />
                      </div>
                    )}

                    <div className='flex flex-col w-full mb-2 lg:mb-6'>
                      <label className='mb-3 flex justify-start items-center'>
                        Case hearing date and time
                      </label>

                      <input
                        className={`  border-gray-200 appearance-none border rounded-md w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
                        name='scheduled_at'
                        onChange={handleChange}
                        type='datetime-local'
                        value={values.scheduled_at}
                      />
                    </div>
                    <div className='flex flex-col   w-full mb-2 lg:mb-6'>
                      <label className='mb-3 flex justify-start items-center'>
                        Hearing type
                      </label>
                      <select
                        className={` border-gray-200 appearance-none border rounded-md  w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
                        name='type'
                        onChange={handleChange}
                        value={values.type}
                      >
                        <option className='text-gray-200' value='' />
                        <option value='virtual'>Virtual</option>
                        <option value='physical'>Physical</option>
                      </select>
                      {touched.type && errors.type && (
                        <span className='text-red-500 text-sm'>
                          {errors.type}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <Button
                  bgColor='bg-brand_indigo'
                  className='py-4  w-full rounded-lg'
                  hoverColor=''
                  isLoading={isSubmitting}
                  onClick={handleSubmit}
                  textColor='text-white'
                  title=' Submit'
                  type='button'
                />
              </>
            </Form>
          )}
        </Formik>
      </Zoom>
    </div>
  );
}

export default ScheduleHearing;
