/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-sort-props */

import "@livekit/components-styles";

import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
} from "@livekit/components-react";
import { Track } from "livekit-client";
import { useSearchParams } from "react-router-dom";

function MyVideoConference() {
  // `useTracks` returns all camera and screen share tracks. If a user
  // joins without a published camera track, a placeholder track is returned.
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false },
  );
  return (
    <GridLayout
      style={{ height: "calc(100vh - var(--lk-control-bar-height))" }}
      tracks={tracks}
    >
      {/* The GridLayout accepts zero or one child. The child is used
        as a template to render all passed in tracks. */}
      <ParticipantTile />
    </GridLayout>
  );
}

export default function Room() {
  const [urlParams] = useSearchParams();
  const token = urlParams.get("token");

  const serverUrl = "wss://virtual-hearing-p96d8avq.livekit.cloud";

  return (
    <>
      {token && (
        <LiveKitRoom
          audio
          style={{ height: "100vh" }}
          token={token}
          video
          serverUrl={serverUrl}
          // Use the default LiveKit theme for nice styles.
          data-lk-theme='default'
        >
          {/* Your custom component with basic video conferencing functionality. */}
          <MyVideoConference />
          {/* The RoomAudioRenderer takes care of room-wide audio for you. */}
          <RoomAudioRenderer />
          {/* Controls for the user to start/stop audio, video, and screen
      share tracks and to leave the room. */}
          <ControlBar />
        </LiveKitRoom>
      )}
    </>
  );
}
