const CourtDocumentsData = [
  {
    id: 1,
    name: "FORM 1 GENERAL FORM OF COMPLAINT",
    path: "https://docs.google.com/document/d/1W5TA2bsdfzMbIlQ5VL6-fj-m9s_4FMMa/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 2,
    name: "FORM 2 GENERAL FORM OF SUMMONS",
    path: "https://docs.google.com/document/d/1Tkx84OPkQUQ04l0g0IHCS0PYQOqYp_TU/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 3,
    name: "FORM 3 GENERAL FORM OF ORIGINATING MOTION",
    path: "https://docs.google.com/document/d/1RTAX4PfhKrMg6Gowwba4HVV3a4XJwKHG/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 4,
    name: "FORM 4 ORIGINATING SUMMONS",
    path: "https://docs.google.com/document/d/1Qd6F-BWF_HnHDAbeEmLccKUUAF6OOkMb/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 5,
    name: "FORM 5 AFFIDAVIT OF SERVICE",
    path: "https://docs.google.com/document/d/1CKcl2kehM9yldtagmw4L7WhYpa2NXugA/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 6,
    name: "FORM 6 FORM OF STATEMENT OF TRUTH 4",
    path: "https://docs.google.com/document/d/1TCmdtJ8iNfHD2t3whkW_cAbII_ip1wPg/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 7,
    name: "FORM 7 FORM OF STATEMENT OF TRUTH",
    path: "https://docs.google.com/document/d/1cuH_KlVzbAY9uSopG9xrYZVnLB5EwXKL/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 8,
    name: "FORM 8 NOTICE TO DEFEND",
    path: "https://docs.google.com/document/d/1iYth3pWvyUb_58plc91NzPuKo-ca1kI7/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 9,
    name: "FORM 9 WITNESS SUMMONS",
    path: "https://docs.google.com/document/d/1vJFJojVdVdjPf-Tb48eN79SF03ksT4lz/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 10,
    name: "FORM 10 INTERLOCUTORY APPLICATIONS ON NOTICE",

    path: "https://docs.google.com/document/d/1J82fzOUbv2pJz5zAesha5PCMG7duY0oC/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 11,
    name: "FORM 11 INTERLOCUTORY APPLICATIONS EX PARTE",
    path: "https://docs.google.com/document/d/15D6wFF-Mw-Mm1YKgYCb_gYOtOlYWQX2x/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 12,
    name: "FORM 12 DECLARATION REGARDING SUITABILITY TO FOSTER CHILDREN PRIVATELY",
    path: "https://docs.google.com/document/d/1i2F77V5dXzNoLH_16uTA8iveG2OGOijM/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 13,
    name: "FORM 13 FORM FOR NOTICE TO PARENT OR GUARDIAN CARE OR PROTECTION",
    path: "https://docs.google.com/document/d/10lMCvH5_eE0UTW8ayuo7n3lrcm0mar3U/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 14,
    name: "FORM 14 FORM FOR SUMMONS FOR ATTENDANCE OF PARENT OR GUARDIAN",
    path: "https://docs.google.com/document/d/1Pbz0O-69q662aBR3iWKqjv3QrP5-oeQS/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 15,
    name: "FORM 15 FORM FOR NOTICE TO PARENT",
    path: "https://docs.google.com/document/d/1xtXf8i4GiUJMfvyqahp-voYEM0KOJBGc/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 16,
    name: "FORM 16 AUTHORITY FOR THE PRODUCTION OF A CHILD",
    path: "https://docs.google.com/document/d/1821oWHx9srAlIwFC8fkPm6YWgHXIeEDT/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  // src/pages/DownloadForm
  {
    id: 17,
    name: "FORM 17 AUTHORITY FOR REMAND AND COMMITTAL TO STATE ACCOMMODATION AND APPROVED INSTITUTIONS",
    path: "https://docs.google.com/document/d/1hw8ujlFwpqABSFIP68qsgimgSHUUq28I/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 18,
    name: "FORM 18 SUMMONS TO PARENT TO CONTRIBUTE TO MAINTENANCE",
    path: "https://docs.google.com/document/d/14aSa00gihTBiN1o6hZ3OLRdZT4iONp8D/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 19,
    name: "FORM 19 DISPOSAL PENDING OPERATION OF COMMITTAL ORDER",
    path: "https://docs.google.com/document/d/1i4lIoLJPBCZiO3xddczarFLdCIS_yfRe/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 20,
    name: "FORM 20 RECOGNIZANCE AND SUPERVISION ORDER",
    path: "https://docs.google.com/document/d/1jJV79Jk42KsjeVrWEgL0hga__LrpPLlq/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 21,
    name: "FORM 21 FORM FOR NOTICE OF CHANGE OF SUPERVISION OFFICER 2",
    path: "https://docs.google.com/document/d/1sPbRBJBylLPwhFwNEyr5Bd7w31QFhMmP/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 22,
    name: "FORM 22 NOTICE OF INTENTION TO PROCEED WITH",
    path: "https://docs.google.com/document/d/1m9GCjd-6QxnhhKTVaCBF1zOiDZJbBkO_/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 23,
    name: "FORM 23 FINANCIAL STATEMENT",
    path: "https://docs.google.com/document/d/1D1DeXdEVeLsWdHk838JmYOQw0SrEi5uE/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 24,
    name: "FORM 24 FORM OF ADOPTED CHILDREN S REGISTER",
    path: "https://docs.google.com/document/d/1QwcDxmkZCZNcF-XARA2Onm7E7dZAVCoR/edit?usp=drive_link&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 25,
    name: "FORM 25 PRAECIPE FOR JUDGMENT SUMMONS 2",

    path: "https://docs.google.com/document/d/1jwlRyjLTCpLqD7gSRA67vSCtr25n0WFU/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
  {
    id: 26,
    name: "FORM 26 NOTICE OF APPEAL",
    path: "https://docs.google.com/document/d/1LdoeGi4NtAmKEseT9Rfgd0PruRiWNLV1/edit?usp=sharing&ouid=112732752247243516705&rtpof=true&sd=true",
  },
];

export default CourtDocumentsData;
