/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { AiFillPlusCircle } from "react-icons/ai";

import { SEARCH_WHITE } from "../../assets";
import Button from "../../components/button";
import Input from "../../components/form/Input";
import { NavbarWithMegaMenu } from "../../components/Navbar";
import { CASE_CAUSELIST_COLUMNS } from "../../components/table/columns/Lawyer";
import { TableComponent } from "../../components/table/Table";
import Footer from "../../components/UI/Footer";
import { API } from "../../config";

function CauseList() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [causeListData, setCauseListData] = useState<any>();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(3);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [selectedStartDate, setSelectedStartDate] = useState<any>();

  const handleStartDateChange = (newDate: any) => {
    setSelectedStartDate(newDate.target.value);
  };

  const handleDateChange = (newDate: any) => {
    setSelectedDate(newDate.target.value);
  };

  // Get the current date
  const currentDate = moment();

  // Calculate the start of the week (Sunday)
  const startOfWeek = currentDate.clone().startOf("week");

  // Calculate the end of the week (Saturday)
  const endOfWeek = currentDate.clone().endOf("week");

  // Format the start and end of the week as desired
  const startOfWeekFormatted = startOfWeek.format("YYYY-MM-DD");
  const endOfWeekFormatted = endOfWeek.format("YYYY-MM-DD");

  const handleGetCauseList = (data: any) => {
    if (data?.name || data?.suit_number || data?.title) {
      setIsLoading(true);
      API.get(
        `cause-lists?name=${data?.name}&suit_number=${data?.suit_number}&title=${
          data?.title
        }&start_date=${selectedStartDate || startOfWeekFormatted}&end_date=${
          selectedDate || endOfWeekFormatted
        }`,
      )
        .then(({ data }) => {
          setCauseListData(data.data);
          // toast.success("Retrieved Cause List Information Successfully");
          setIsLoading(false);
        })
        .catch((error: any) => {
          // console.log(error.response.data.message);

          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      API.get(
        `cause-lists?&start_date=${selectedStartDate || startOfWeekFormatted}&end_date=${
          selectedDate || endOfWeekFormatted
        }`,
      )
        .then(({ data }) => {
          setCauseListData(data.data);
          // toast.success("Retrieved Cause List Information Successfully");
          setIsLoading(false);
        })
        .catch((error: any) => {
          // console.log(error.response.data.message);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    (() => {
      API.get(
        `cause-lists?&start_date=${selectedStartDate || startOfWeekFormatted}&end_date=${
          selectedDate || endOfWeekFormatted
        }`,
      )
        .then(({ data }) => {
          setCauseListData(data.data);
          // toast.success("Retrieved Cause List Information Successfully", {
          // duration: 3000, // Duration for which the toast is displayed (in milliseconds)
          // });
          setIsLoading(false);
        })
        .catch((error: any) => {
          // console.log(error.response.data.message);
          // toast.error(error?.response?.data?.message, {
          //   duration: 3000,
          // });
          setIsLoading(false);
        });
    })();
  }, [
    selectedDate,
    selectedStartDate,
    endOfWeekFormatted,
    startOfWeekFormatted,
  ]);

  return (
    <div>
      <div className='sticky top-0  z-50 '>
        <NavbarWithMegaMenu />
      </div>
      <div className='flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 '>
        <p className='text-center text-xl font-bold'>Cause List</p>

        <Formik
          initialValues={{ judges: "", parties: "", suit: "" }}
          // validationSchema={loginSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const data = {
              name: values.judges,
              title: values.parties,
              suit_number: values.suit,
            };

            handleGetCauseList(data);
          }}
        >
          {({ handleSubmit, isSubmitting, resetForm }) => (
            <form
              className='flex flex-col pt-3 md:pt-8 w-full lg:w-[70%] mx-auto'
              onSubmit={handleSubmit}
            >
              <Fade direction='left'>
                <>
                  <div className='flex justify-between items-start flex-wrap w-full'>
                    <div className='lg:flex flex-col justify-start items-start   w-full lg:w-[32%]'>
                      <label
                        className='text-base pt-4 ml-2 font-semibold'
                        htmlFor='email'
                      >
                        Judges Name
                      </label>
                      <div className='w-full '>
                        <Input
                          name='judges'
                          placeholder='Enter Judges Name'
                          type='text'
                        />
                      </div>
                    </div>
                    <div className='lg:flex flex-col justify-start items-start   w-full lg:w-[32%]'>
                      <label
                        className='text-base pt-4 ml-2 font-semibold'
                        htmlFor='email'
                      >
                        Parties
                      </label>
                      <div className='w-full'>
                        <Input
                          name='parties'
                          placeholder='Enter Parties to the Case'
                          type='text'
                        />
                      </div>
                    </div>
                    <div className='lg:flex flex-col justify-start items-start   w-full lg:w-[32%]'>
                      <label
                        className='text-base pt-4 ml-2 font-semibold'
                        htmlFor='email'
                      >
                        Suit number
                      </label>
                      <div className='w-full'>
                        <Input
                          name='suit'
                          placeholder='Enter Suit Number'
                          type='text'
                        />
                      </div>
                    </div>
                    <div className='lg:flex flex-col justify-start items-start  w-full lg:w-[49%]'>
                      <label
                        className='text-base pt-4 ml-2 font-semibold'
                        htmlFor='email'
                      >
                        Start date
                      </label>
                      <input
                        className={` border-gray-300 appearance-none border rounded-lg w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
                        name='Start date'
                        onChange={handleStartDateChange}
                        type='date'
                        value={selectedStartDate}
                      />
                    </div>
                    <div className='lg:flex flex-col justify-start items-start  w-full lg:w-[49%]'>
                      <label
                        className='text-base pt-4 ml-2 font-semibold'
                        htmlFor='End date'
                      >
                        End date
                      </label>

                      <input
                        className={`  border-gray-300 appearance-none border rounded-lg w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
                        name='End date '
                        onChange={handleDateChange}
                        type='date'
                        value={selectedDate}
                      />
                    </div>
                  </div>
                  <div className='lg:flex flex-row justify-center items-center mt-10 mb-4 w-full'>
                    <Button
                      bgColor='bg-brand_indigo'
                      className=' mb-2 lg:mb-0  w-52 lg:w-80 py-4 rounded-2xl'
                      icons={SEARCH_WHITE}
                      isLoading={isLoading}
                      textColor='text-white'
                      title='Search'
                    />
                  </div>
                </>
              </Fade>
              <Zoom>
                <>
                  {causeListData !== undefined && causeListData?.length > 0 && (
                    <>
                      <div className='mb-6'>
                        <p className='text-xl font-bold'>Case Details</p>
                      </div>
                      <small className='text-gray-400'>
                        Result shown below
                      </small>
                    </>
                  )}

                  <div className='mb-4 rounded-2xl  w-full'>
                    {causeListData?.map((item: any) => (
                      <Accordion>
                        <AccordionSummary
                          aria-controls='panel1a-content'
                          className='shadow-box-shadow'
                          expandIcon={
                            <AiFillPlusCircle className='text-green-900 text-3xl' />
                          }
                          id='panel1a-header'
                        >
                          <h4 className='py-2 font-semibold text-left'>
                            HON. {item?.name}
                          </h4>
                        </AccordionSummary>

                        {item.cause_lists?.length > 0 && (
                          <AccordionDetails>
                            <TableComponent
                              className='rounded-none rounded-t-0 '
                              COLUMNS={CASE_CAUSELIST_COLUMNS}
                              DATA={item.cause_lists}
                              isNotPaginanated
                              page={page}
                              setPage={setPage}
                            />
                          </AccordionDetails>
                        )}
                      </Accordion>
                    ))}
                  </div>

                  {/* <div className='mb-6'>
											{causeListData !== undefined && causeListData?.length > 0 ? (
												causeListData?.map((item: any) => (
													<>
														<Accordion>
															<AccordionSummary
																expandIcon={<MdExpandMore />}
																aria-controls='panel1a-content'
																id='panel1a-header'
															>
																<div>
																	<h4 className='py-2 font-semibold text-left'>{`Hon ${item?.user?.first_name} ${item?.user?.last_name}`}</h4>

																	<h4 className='py-2 font-medium text-left'>{`${item?.title}`}</h4>
																	<h4 className='py-2 font-medium text-left'>{`${item?.case_folder?.suit_number}`}</h4>

																	<h4 className='py-2 font-medium text-left capitalize'>{`${item?.type} `}</h4>
																	<h4 className='py-2 font-medium text-left capitalize'>
																		{moment(item?.scheduled_at).format('MMM DD, YYYY')}
																	</h4>
																	{item?.court && (
																		<h4 className='py-2 font-medium text-left capitalize'>{`${item?.court} `}</h4>
																	)}
																	{item?.join_url && (
																		<a
																			target='_blank'
																			href={item.join_url}
																			className='px-4  self-end py-1 mr-4 bg-brand_indigo text-center text-[#fff] text-xs  w-20  rounded-md'
																			rel='noreferrer'
																		>
																			Join
																		</a>
																	)}
																</div>
															</AccordionSummary>
														</Accordion>
													</>
												))
											) : causeListData !== undefined && causeListData?.length < 1 ? (
												<div className='flex flex-col justify-center items-center h-52'>
													<img src={FOLDER} className='h-20' alt='' />
													<p className='text-center text-xs'>
														No Case Folder available at the moment
													</p>
												</div>
											) : null}
										</div> */}
                </>
              </Zoom>
            </form>
          )}
        </Formik>
      </div>
      <Footer />
    </div>
  );
}

export default CauseList;
