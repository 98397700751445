/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { IconButton } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../hooks";
import {
  setToggle,
  setToggle_2,
  setToggle_3,
  updateCase,
} from "../../../states/slices/partyInfo.slice";
import DropDownOptions from "../../dropdownBtn/DropDownOptions";

export const CASE_CAUSELIST_COLUMNS = [
  {
    Header: "S/No.",

    Cell: ({ cell: { row } }: any) => (
      <span className='text-xs'>{Number(row.id) + 1}</span>
    ),
  },

  {
    Header: "Case Title",
    // accessor: 'title',
    Cell: ({ cell: { row } }: any) => (
      <span className='text-xs'>{row.original?.case_title}</span>
    ),
  },
  {
    Header: "Suit Number",

    accessor: "suit_number",
  },
  {
    Header: "Hearing Description",
    accessor: "description",
  },
  {
    Header: "Scheduled Date",
    accessor: "created_at",
    Cell: ({ cell: { row } }: any) =>
      moment(row.original.sitting_at).format("MMM Do YYYY | hh:mm a"),
  },
  {
    Header: "Hearing Type",
    accessor: "type",
  },
  // {
  //   Header: "Meeting Link",
  //   accessor: "join_url",
  // },
  {
    Header: "Court",
    accessor: "court",
  },

  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      const navigate = useNavigate();
      return (
        <>
          {row.original?.type === "physical" ? (
            <></>
          ) : (
            <a
              className='px-4 py-1 bg-brand_indigo text-[#fff] text-xs  w-20  rounded-md'
              onClick={() =>
                navigate(`/join-hearing?title=${row.original.room_name}`)
              }
              rel='noreferrer'
              target='_blank'
            >
              Join
            </a>
          )}
        </>
      );
    },
  },

  {
    Header: "Status",
    Cell: ({ cell: { row } }: any) => (
      <button
        className={`px-2 py-1 text-gray-500 text-xs cursor-default  rounded-md capitalize ${
          row.original?.status === "PENDING"
            ? "bg-yellow-200"
            : row.original?.status === "IN COURT"
              ? "bg-[#F2994A]"
              : row.original?.status === "RECALLED"
                ? "bg-red-200"
                : "bg-green-200"
        }`}
      >
        {row.original?.status}
      </button>
    ),
  },
];

export const POST_COLUMNS = [
  {
    Header: "ID",

    Cell: ({ cell: { row } }: any) => (
      <span className='text-xs'>{Number(row.id) + 1}</span>
    ),
  },

  {
    Header: "News Title",
    // accessor: 'title',
    Cell: ({ cell: { row } }: any) => (
      <span className='text-xs'>{row.original?.title.slice(0, 50)} ...</span>
    ),
  },

  {
    Header: "Date Created",
    accessor: "created_at",
    Cell: ({ cell: { row } }: any) =>
      moment(row.original.created_at).format("MMM Do YYYY | hh:mm a"),
  },
  {
    Header: "Date Updated",
    accessor: "updated_at",
    Cell: ({ cell: { row } }: any) =>
      moment(row.original.updated_at).format("MMM Do YYYY | hh:mm a"),
  },

  {
    Header: "Image",
    Cell: ({ cell: { row } }: any) => (
      <>
        <img alt='' className='h-20 w-32' src={row?.original?.image} />
      </>
    ),
  },
  {
    Header: "Actions",
    Cell: ({ cell: { row } }: any) => {
      const dispatch = useAppDispatch();
      return (
        <div className='flex gap-5'>
          <IconButton
            onClick={() => {
              dispatch(setToggle_2(true));
              dispatch(updateCase(row?.original));
            }}
          >
            <PencilSquareIcon className='h-8 text-green-700' />
          </IconButton>
          <IconButton
            onClick={() => {
              dispatch(setToggle_3(true));
              dispatch(updateCase(row?.original));
            }}
          >
            <TrashIcon className='h-8 text-red-800' />
          </IconButton>
        </div>
      );
    },
  },
];

export const CASE_COLUMNS = [
  {
    Header: "S/No.",

    Cell: ({ cell: { row } }: any) => (
      <span className='text-xs'>{Number(row.id) + 1}</span>
    ),
  },
  {
    Header: "Case Title",
    accessor: "title",
  },
  {
    Header: "Status",
    // accessor: '',
    Cell: ({ cell: { row } }: any) => (
      <button
        className={`px-2 py-1  text-center text-xs cursor-default  rounded-md capitalize ${
          row.original?.case_status?.status === "PENDING"
            ? "text-yellow-700 bg-yellow-100"
            : row.original?.case_status?.status === "MOTION"
              ? "text-yellow-700 bg-yellow-100"
              : row.original?.case_status?.status === "IN COURT"
                ? "text-orange-800 bg-orange-100"
                : row.original?.case_status?.status === "NOT SERVED"
                  ? "text-neutral-200 bg-neutral-800"
                  : row.original?.case_status?.status === "REJECTED"
                    ? "text-red-800 bg-red-100"
                    : row.original?.case_status?.status === "FRESH CASE"
                      ? "text-green-700 bg-green-100 "
                      : row.original?.case_status?.status === "ASSIGN"
                        ? "text-orange-800 bg-orange-100"
                        : row.original?.case_status?.status ===
                            "AWAITING_SERVICE"
                          ? "text-blue-700 bg-blue-100"
                          : "text-green-700 bg-green-100 "
        }`}
        onClick={() => {}}
      >
        {row.original?.case_status?.status === "AWAITING_SERVICE"
          ? "Ready For Service"
          : row.original?.case_status?.status}
      </button>
    ),
  },

  {
    Header: "Payment",
    accessor: "payment_status",
  },

  {
    Header: "Query Status",
    accessor: "case_query",
  },
  {
    Header: "Suit number",
    accessor: "suit_number",
  },
  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      const navigate = useNavigate();
      const dispatch = useDispatch();
      return (
        <DropDownOptions
          actionTitle='Open Folder'
          details
          handleDelete={() => {}}
          handleDetails={() => {
            dispatch(setToggle(true));
            dispatch(updateCase(row.original));
          }}
          handleRename={() => {}}
          handleView={() =>
            navigate(
              `documents?id=${row.original.id}&name=${row.original?.title}`,
              {
                state: { data: row.original },
              },
            )
          }
          isNotDelete
        />
      );
    },
  },
];
