import {
  Hon_Justice_A_O_Onovo,
  Hon_Justice_C_A_Ogbuabor,
  Hon_Justice_C_C_Ani_PhD,
  Hon_Justice_C_I_Nwobodo,
  Hon_Justice_C_V_C_Ezeugwu,
  Hon_Justice_Cyprian_Ogbu_Ajah_PhD_FIIA_FAGS,
  Hon_Justice_E_M_Egumgbe,
  Hon_Justice_E_N_Alukwu,
  Hon_Justice_Enu_Nkem_Oluedo,
  Hon_Justice_H_O_Eya,
  Hon_Justice_H_U_Ezugwu,
  Hon_Justice_J_L_C_Okibe,
  Hon_Justice_K_I_Okpe,
  Hon_Justice_M_C_Eluke,
  Hon_Justice_M_N_Aroh_Onuoha,
  Hon_Justice_N_N_Neboh,
  Hon_Justice_Ngozika_Rosemary_OJI,
  Hon_Justice_P_C_Ugwueze,
  Hon_Justice_R_O_Odugu,
  Hon_Justice_U_J_Mogboh,
  Hon_Justice_Veronica_Chinyere_Ajogwu,
} from "../assets";

const judges = [
  {
    imgSrc: Hon_Justice_A_O_Onovo,
    name: "HON. Justice A. O. Onovo",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_N_N_Neboh,
    name: "HON. Justice R. O. Odugu",

    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_C_I_Nwobodo,
    name: "HON. Justice C. I. Nwobodo",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_R_O_Odugu,
    name: "HON. Justice N. N. Neboh",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_Enu_Nkem_Oluedo,
    name: "HON. Justice E. N. Oluedo",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_Ngozika_Rosemary_OJI,
    name: "HON. Justice R. N. Oji",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_Cyprian_Ogbu_Ajah_PhD_FIIA_FAGS,
    name: "HON. Justice C. O. Ajah, Ph.D, FIIA",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_K_I_Okpe,
    name: "HON. Justice K. I. Okpe",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_C_C_Ani_PhD,
    name: "HON. Justice C. C. Ani, Ph.D., MCIArb.",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_H_O_Eya,
    name: "HON. Justice H. O. Eya",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_E_M_Egumgbe,
    name: "HON. Justice E. M. Egumgbe, FCIArb, FICMC",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_J_L_C_Okibe,
    name: "HON. Justice J. L. C. Okibe",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_Veronica_Chinyere_Ajogwu,
    name: "HON. Justice V. C. Ajogwu, FICMC, MCIArb.",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_E_N_Alukwu,
    name: "HON. Justice E. N. Alukwu",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_C_V_C_Ezeugwu,
    name: "HON. Justice C. V. C. Ezeugwu",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_C_A_Ogbuabor,
    name: "HON. Justice C. A. Ogbuabor. Ph.D., FICMC",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_M_C_Eluke,
    name: "HON. Justice M. C. Eluke",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_M_N_Aroh_Onuoha,
    name: "HON. Justice M. N. Aroh-Onuoha, OSF, FCAI",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_P_C_Ugwueze,
    name: "HON. Justice P. C. Ugwueze",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_H_U_Ezugwu,
    name: "HON. Justice H. U. Ezeugwu, Ph. D",
    title: "Admin Judge, Enugu Judicial Division",
  },
  {
    imgSrc: Hon_Justice_U_J_Mogboh,
    name: "HON. Justice U. J. Mogboh",
    title: "Admin Judge, Enugu Judicial Division",
  },
  // {
  //   imgSrc: Hon_Justice_O_P_Anike,
  //   name: "HON. Justice O. P. Anike",
  //   title: "Admin Judge, Enugu Judicial Division",
  // },
];

export default judges;
