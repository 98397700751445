/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Navbar,
} from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";

const navListMenuItems = [
  {
    title: "Hon Judges",
    // description: "Find the perfect solution for your needs.",
    icon: UserGroupIcon,
    path: "/judges",
  },
  {
    title: "Chief Registrar & DCR's",

    icon: UserGroupIcon,
    path: "/chief-Registrar-dcr-judges",
  },
  {
    title: "Magistrates",
    icon: UserGroupIcon,
    path: "/magistrates",
  },

  {
    title: "Principal Officers",

    icon: UserGroupIcon,
    path: "/principal-officers",
  },
  {
    title: "Retired Judges",

    icon: UserGroupIcon,
    path: "/retired-judges",
  },
  {
    title: "Retired Chief Regstrar's",

    icon: UserGroupIcon,
    path: "/retired-chief-registrars",
  },
];

const featuresMenuItems = [
  {
    title: "Litigation Module",
    icon: UserGroupIcon,
    path: "/login",
  },
  {
    title: "Virtual Hearing",

    icon: UserGroupIcon,
    path: "/virtual-hearing",
  },
  {
    title: "e-Affidavit",
    icon: UserGroupIcon,
    path: "https://enugu.lextechgroup.com/",
  },
  {
    title: "e-Lis Pendens",
    icon: UserGroupIcon,
    path: "https://lis-pendens-enugu.vercel.app/",
  },
  {
    title: "⁠e-Probate",
    icon: UserGroupIcon,
    path: "#",
  },
];

function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const renderItems = navListMenuItems.map(({ icon, title, path }, key) => (
    <Link key={key} to={path}>
      <MenuItem
        className='flex items-center gap-3 rounded-lg font-clash'
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        placeholder={undefined}
      >
        <div className='flex items-center rounded-full justify-center  bg-gray-700  p-2 '>
          {" "}
          {React.createElement(icon, {
            strokeWidth: 2,
            className: "h-6 text-green-100 w-6",
          })}
        </div>
        <div>
          <h6 className='flex items-center text-sm font-normal'>{title}</h6>
        </div>
      </MenuItem>
    </Link>
  ));
  const renderFeaturesItems = featuresMenuItems.map(
    ({ icon, title, path }, key) => (
      <Link key={key} to={path}>
        <MenuItem
          className='flex items-center gap-3 rounded-lg font-clash'
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          placeholder={undefined}
        >
          <div className='flex items-center rounded-full justify-center  bg-gray-700  p-2 '>
            {" "}
            {React.createElement(icon, {
              strokeWidth: 2,
              className: "h-6 text-green-100 w-6",
            })}
          </div>
          <div>
            <h6 className='flex items-center text-sm font-normal'>{title}</h6>
          </div>
        </MenuItem>
      </Link>
    ),
  );

  return (
    <>
      <Menu
        allowHover
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        open={isMenuOpen}
        placement='bottom'
      >
        <MenuHandler>
          <div>
            <ListItem
              className='flex items-center gap-2 font-clash py-2 pr-4 font-medium text-green-100'
              onClick={() => setIsMobileMenuOpen((current) => !current)}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              placeholder={undefined}
              selected={isMenuOpen || isMobileMenuOpen}
            >
              Officials
              <ChevronDownIcon
                className={`hidden h-3 w-3 font-clash transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
                strokeWidth={2.5}
              />
              <ChevronDownIcon
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
                strokeWidth={2.5}
              />
            </ListItem>
          </div>
        </MenuHandler>
        <MenuList
          className='hidden max-w-screen-xl font-clash bg-black rounded-xl lg:block'
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          placeholder={undefined}
        >
          <ul className='grid grid-cols-3 gap-y-2 outline-none bg-black text-green-100 outline-0'>
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className='block lg:hidden'>
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </>
  );
}
function FeatiuresListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const renderFeaturesItems = featuresMenuItems.map(
    ({ icon, title, path }, key) => (
      <Link key={key} to={path}>
        <MenuItem
          className='flex items-center gap-3 rounded-lg font-clash'
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          placeholder={undefined}
        >
          <div className='flex items-center rounded-full justify-center  bg-gray-700  p-2 '>
            {" "}
            {React.createElement(icon, {
              strokeWidth: 2,
              className: "h-6 text-green-100 w-6",
            })}
          </div>
          <div>
            <h6 className='flex items-center text-sm font-normal'>{title}</h6>
          </div>
        </MenuItem>
      </Link>
    ),
  );

  return (
    <>
      <Menu
        allowHover
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        open={isMenuOpen}
        placement='bottom'
      >
        <MenuHandler>
          <div>
            <ListItem
              className='flex items-center gap-2 font-clash py-2 pr-4 font-medium text-green-100'
              onClick={() => setIsMobileMenuOpen((current) => !current)}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              placeholder={undefined}
              selected={isMenuOpen || isMobileMenuOpen}
            >
              E - Services
              <ChevronDownIcon
                className={`hidden h-3 w-3 font-clash transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
                strokeWidth={2.5}
              />
              <ChevronDownIcon
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
                strokeWidth={2.5}
              />
            </ListItem>
          </div>
        </MenuHandler>
        <MenuList
          className='hidden max-w-screen-xl font-clash bg-black rounded-xl lg:block'
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          placeholder={undefined}
        >
          <ul className='grid grid-cols-3 gap-y-2 outline-none bg-black text-green-100 outline-0'>
            {renderFeaturesItems}
          </ul>
        </MenuList>
      </Menu>
      <div className='block lg:hidden'>
        <Collapse open={isMobileMenuOpen}>{renderFeaturesItems}</Collapse>
      </div>
    </>
  );
}

function NavList() {
  return (
    <List
      className='mt-4 mb-6 p-0 lg:mt-0  font-clash lg:mb-0 lg:flex-row lg:p-1 text-green-100'
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      placeholder={undefined}
    >
      <Link className='font-medium' to='/'>
        <ListItem
          className='flex items-center font-clash gap-2 py-2 pr-4 text-green-100'
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          placeholder={undefined}
        >
          Home
        </ListItem>
      </Link>
      <Link className='font-medium' to='/about-us'>
        <ListItem
          className='flex items-center font-clash gap-2 py-2 pr-4 text-green-100'
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          placeholder={undefined}
        >
          About Us
        </ListItem>
      </Link>
      <NavListMenu />

      <Link className='font-medium' to='/cause-lists'>
        <ListItem
          className='flex items-center font-clash gap-2 py-2 pr-4 text-green-100'
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          placeholder={undefined}
        >
          Cause Lists
        </ListItem>
      </Link>
      <Link className='font-medium' to='/court-documents'>
        <ListItem
          className='flex items-center font-clash gap-2 py-2 pr-4 text-green-100'
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          placeholder={undefined}
        >
          Court Documents
        </ListItem>
      </Link>
      <Link className='font-medium' to='/news-and-events'>
        <ListItem
          className='flex items-center font-clash gap-2 py-2 pr-4 text-green-100'
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          placeholder={undefined}
        >
          News & Events
        </ListItem>
      </Link>

      <FeatiuresListMenu />

      {/* <Link className='font-medium' to='/contact-us'>
        <ListItem
          className='flex items-center font-clash gap-2 py-2 pr-4 text-green-100'
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          placeholder={undefined}
        >
          Contact Us
        </ListItem>
      </Link> */}
    </List>
  );
}

export function NavbarWithMegaMenu() {
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);

  return (
    <div className='w-screen bg-black'>
      <Navbar
        className='mx-auto w-screen px-4 py-2 font-clash border-none  sticky top-0 z-10 bg-black'
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        placeholder={undefined}
      >
        <div className='flex justify-between items-center w-full flex-wrap'>
          <div>
            <span className='text-green-100 font-bold text-xl ml-20'>
              Enugu State Judiciary
            </span>
          </div>
          <div className='flex justify-end items-center  mb-6 '>
            <div className='text-green-100 flex justify-start'>
              <svg
                className='w-6 h-6'
                fill='none'
                stroke='currentColor'
                strokeWidth={1.5}
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <div className='ml-4'>
                <p className=''>Opening Times</p>
                <p className=' text-gray-500'>Mon - Fri 08:00 am - 4:00 pm</p>
              </div>
            </div>
            <div className='text-green-100 flex justify-start ml-6'>
              <svg
                className='w-6 h-6'
                fill='none'
                stroke='currentColor'
                strokeWidth={1.5}
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>

              <div className='ml-4'>
                <p className=''>Inquires</p>
                <p className=' text-gray-500'>+234 704498857</p>
              </div>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-between text-green-100 '>
          <a className='mr-4 cursor-pointer py-1.5 lg:ml-2' href='#'>
            {/* <img alt='' className='h-20' src={LOGO} /> */}
          </a>
          <div className='hidden lg:block'>
            <NavList />
          </div>
          <div className='hidden gap-2 lg:flex border-2 border-green-100 py-1 px-2 rounded hover:scale-110 transition-all ease-in-out duration-500'>
            <Link to='/login'>Log In</Link>
          </div>
          <IconButton
            className='lg:hidden'
            color='blue-gray'
            onClick={() => setOpenNav(!openNav)}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            placeholder={undefined}
            variant='text'
          >
            {openNav ? (
              <XMarkIcon className='h-6 w-6' strokeWidth={2} />
            ) : (
              <Bars3Icon className='h-6 w-6' strokeWidth={2} />
            )}
          </IconButton>
        </div>

        <Collapse open={openNav}>
          <NavList />

          <div className='flex w-full flex-nowrap items-center gap-2 lg:hidden'>
            <Link to='/login'>Log In</Link>
          </div>
        </Collapse>
      </Navbar>
    </div>
  );
}
