import { BsCreditCard2FrontFill } from "react-icons/bs";
import { FaBell } from "react-icons/fa";

import { BELL_WHITE, FOLDERICON, OVERVIEW, SETTINGS, USER } from "../../assets";

export const LawyerSidebar = [
  {
    id: "1",
    title: "Overview",
    icon: <img alt='' className='h-6 mr-3' src={OVERVIEW} />,
    alt: "..",
    path: "/dashboard",
  },
  {
    id: "4",
    title: "Schedule Hearing",
    icon: <img alt='' className='h-6 mr-3' src={USER} />,
    alt: "bell...",
    path: "/dashboard/schedule-hearing",
  },
  {
    id: "2",
    title: "Cause Lists",
    icon: <img alt='' className='h-6 mr-3' src={FOLDERICON} />,
    alt: "..",
    path: "/dashboard/cause-list",
  },

  {
    id: "5",
    title: "Settings",
    icon: <img alt='' className='h-6 mr-3' src={SETTINGS} />,
    alt: "user...",
    path: "/dashboard/settings",
  },
];

export const adminSidebar = [
  {
    id: "1",
    title: "Overview",
    icon: <img alt='' className='h-6 mr-3' src={OVERVIEW} />,
    alt: "..",
    path: "/admin",
  },
  {
    id: "4",
    title: "All Posts",
    icon: <img alt='' className='h-6 mr-3' src={USER} />,
    alt: "bell...",
    path: "/admin/all-posts",
  },
  {
    id: "2",
    title: "Create Posts",
    icon: <img alt='' className='h-6 mr-3' src={FOLDERICON} />,
    alt: "..",
    path: "/admin/create-posts",
  },

  {
    id: "5",
    title: "Settings",
    icon: <img alt='' className='h-6 mr-3' src={SETTINGS} />,
    alt: "user...",
    path: "/admin/settings",
  },
];
export const NonlawyerSidebar = [
  {
    id: "1",
    title: "Dashboard",
    icon: <img alt='' className='h-6 mr-3' src={OVERVIEW} />,
    alt: "pie...",
    path: "/non-lawyer",
  },
  {
    id: "2",
    title: "My Files",
    icon: <img alt='' className='h-6 mr-3' src={FOLDERICON} />,
    alt: "..",
    path: "/non-lawyer/my-files",
  },

  {
    id: "3",
    title: "Notifications",
    icon: <img alt='' className='h-6 mr-3' src={BELL_WHITE} />,
    alt: "bell...",
    path: "/non-lawyer/notification",
  },
  {
    id: "5",
    title: "Settings",
    icon: <img alt='' className='h-6 mr-3' src={SETTINGS} />,
    alt: "user...",
    path: "/non-lawyer/profile",
  },
  // {
  // 	id: '6',
  // 	title: 'Payment',
  // 	icon: BsCreditCard2FrontFill,
  // 	alt: 'pay...',
  // 	path: '/lawyer/payment',
  // },
];

export const RegistrarSidebar1 = [
  {
    id: "1",
    title: "Overview",
    icon: <img alt='' className='h-6 mr-3' src={OVERVIEW} />,
    alt: "..",
    path: "/registrar-1",
  },
  {
    id: "2",
    title: "Case folders",
    icon: <img alt='' className='h-6 mr-3' src={FOLDERICON} />,
    alt: "..",
    path: "/registrar-1/case-folders",
  },

  {
    id: "3",
    title: "Notifications",
    icon: <img alt='' className='h-6 mr-3' src={BELL_WHITE} />,
    alt: "bell...",
    path: "/registrar-1/notification",
  },

  {
    id: "4",
    title: "Settings",
    icon: <img alt='' className='h-6 mr-3' src={SETTINGS} />,
    alt: "user...",
    path: "/registrar-1/profile",
  },
];
export const RegistrarSidebar2 = [
  {
    id: "1",
    title: "Dashboard",
    icon: <img alt='' className='h-6 mr-3' src={OVERVIEW} />,
    alt: "pie...",
    path: "/registrar-2",
  },
  {
    id: "2",
    title: "Case Folders",
    icon: <img alt='' className='h-6 mr-3' src={FOLDERICON} />,
    alt: "..",
    path: "/registrar-2/case-folders",
  },

  {
    id: "3",
    title: "Notifications",
    icon: <img alt='' className='h-6 mr-3' src={BELL_WHITE} />,
    alt: "bell...",
    path: "/registrar-2/notification",
  },

  // {
  // 	id: '5',
  // 	title: 'Concluded Cases',
  // 	icon: BsCreditCard2FrontFill,
  // 	alt: 'pay...',
  // 	path: '/registrar-2/concluded-cases',
  // },
  {
    id: "6",
    title: "Profile",
    icon: <img alt='' className='h-6 mr-3' src={SETTINGS} />,
    alt: "user...",
    path: "/registrar-2/profile",
  },
];
export const RegistrarSidebar3 = [
  {
    id: "1",
    title: "Dashboard",
    icon: <img alt='' className='h-6 mr-3' src={OVERVIEW} />,
    alt: "pie...",
    path: "/registrar-3",
  },
  {
    id: "2",
    title: "Case Folders",
    icon: <img alt='' className='h-6 mr-3' src={FOLDERICON} />,
    alt: "..",
    path: "/registrar-3/case-folders",
  },

  {
    id: "3",
    title: "Notifications",
    icon: <img alt='' className='h-6 mr-3' src={BELL_WHITE} />,
    alt: "bell...",
    path: "/registrar-3/notification",
  },

  {
    id: "6",
    title: "Profile",
    icon: <img alt='' className='h-6 mr-3' src={SETTINGS} />,
    alt: "user...",
    path: "/registrar-3/profile",
  },
];
export const presidingMagistrateSidebar = [
  {
    id: "1",
    title: "Dashboard",
    icon: <img alt='' className='h-6 mr-3' src={OVERVIEW} />,
    alt: "pie...",
    path: "/presiding-magistrate",
  },
  {
    id: "2",
    title: "Case Folders",
    icon: <img alt='' className='h-6 mr-3' src={FOLDERICON} />,
    alt: "..",
    path: "/presiding-magistrate/case-folders",
  },

  {
    id: "3",
    title: "Notifications",
    icon: <img alt='' className='h-6 mr-3' src={BELL_WHITE} />,
    alt: "bell...",
    path: "/presiding-magistrate/notification",
  },
  {
    id: "4",
    title: "Magistrates",
    icon: FaBell,
    alt: "bell...",
    path: "/presiding-magistrate/magistrates",
  },

  {
    id: "7",
    title: "Profile",
    icon: <img alt='' className='h-6 mr-3' src={SETTINGS} />,
    alt: "user...",
    path: "/presiding-magistrate/profile",
  },
];
export const PresidingRegistrarSidebar = [
  {
    id: "1",
    title: "Dashboard",
    icon: <img alt='' className='h-6 mr-3' src={OVERVIEW} />,
    alt: "pie...",
    path: "/presiding-judge",
  },
  {
    id: "2",
    title: "Case Folders",
    icon: <img alt='' className='h-6 mr-3' src={FOLDERICON} />,
    alt: "..",
    path: "/presiding-judge/case-folders",
  },

  {
    id: "3",
    title: "Notifications",
    icon: <img alt='' className='h-6 mr-3' src={BELL_WHITE} />,
    alt: "bell...",
    path: "/presiding-judge/notification",
  },
  {
    id: "4",
    title: "Judges",
    icon: FaBell,
    alt: "bell...",
    path: "/presiding-judge/judges",
  },
  // {
  // 	id: '5',
  // 	title: 'Assign',
  // 	icon: FaBell,
  // 	alt: 'bell...',
  // 	path: '/presiding-judge/assign',
  // },

  // {
  // 	id: '6',
  // 	title: 'History',
  // 	icon: BsCreditCard2FrontFill,
  // 	alt: 'pay...',
  // 	path: '/presiding-judge/history',
  // },
  {
    id: "7",
    title: "Profile",
    icon: <img alt='' className='h-6 mr-3' src={SETTINGS} />,
    alt: "user...",
    path: "/presiding-judge/profile",
  },
];
export const ChiefJudgeSidebar = [
  {
    id: "1",
    title: "Dashboard",
    icon: <img alt='' className='h-6 mr-3' src={OVERVIEW} />,
    alt: "pie...",
    path: "/chiefjudge",
  },
  {
    id: "2",
    title: "Case Folders",
    icon: <img alt='' className='h-6 mr-3' src={FOLDERICON} />,
    alt: "..",
    path: "/chiefjudge/case-folders",
  },

  {
    id: "4",
    title: "Judges",
    icon: <img alt='' className='h-6 mr-3' src={USER} />,
    alt: "bell...",
    path: "/chiefjudge/judges",
  },
  {
    id: "3",
    title: "Notifications",
    icon: <img alt='' className='h-6 mr-3' src={BELL_WHITE} />,

    alt: "bell...",
    path: "/chiefjudge/notification",
  },

  {
    id: "7",
    title: "Profile",
    icon: <img alt='' className='h-6 mr-3' src={SETTINGS} />,
    alt: "user...",
    path: "/chiefjudge/profile",
  },
];
export const JudgeSidebar = [
  {
    id: "1",
    title: "Dashboard",
    icon: <img alt='' className='h-6 mr-3' src={OVERVIEW} />,
    alt: "pie...",
    path: "/judge",
  },
  {
    id: "2",
    title: "Case Folders",
    icon: <img alt='' className='h-6 mr-3' src={FOLDERICON} />,
    alt: "..",
    path: "/judge/case-folders",
  },

  {
    id: "3",
    title: "Notifications",
    icon: <img alt='' className='h-6 mr-3' src={BELL_WHITE} />,
    alt: "bell...",
    path: "/judge/notification",
  },
  {
    id: "4",
    title: "Cause list",
    icon: FaBell,
    alt: "bell...",
    path: "/judge/cause-list",
  },

  {
    id: "7",
    title: "Profile",
    icon: <img alt='' className='h-6 mr-3' src={SETTINGS} />,
    alt: "user...",
    path: "/judge/profile",
  },
];
export const MagistrateSidebar = [
  {
    id: "1",
    title: "Dashboard",
    icon: <img alt='' className='h-6 mr-3' src={OVERVIEW} />,
    alt: "pie...",
    path: "/magistrate",
  },
  {
    id: "2",
    title: "Case Folders",
    icon: <img alt='' className='h-6 mr-3' src={FOLDERICON} />,
    alt: "..",
    path: "/magistrate/case-folders",
  },

  {
    id: "3",
    title: "Notifications",
    icon: <img alt='' className='h-6 mr-3' src={BELL_WHITE} />,
    alt: "bell...",
    path: "/magistrate/notification",
  },
  {
    id: "4",
    title: "Cause list",
    icon: FaBell,
    alt: "bell...",
    path: "/magistrate/cause-list",
  },

  {
    id: "7",
    title: "Profile",
    icon: <img alt='' className='h-6 mr-3' src={SETTINGS} />,
    alt: "user...",
    path: "/magistrate/profile",
  },
];
export const BailiffSidebar = [
  {
    id: "1",
    title: "Dashboard",
    icon: <img alt='' className='h-6 mr-3' src={OVERVIEW} />,
    alt: "pie...",
    path: "/bailiff",
  },
  {
    id: "2",
    title: "Case Folders",
    icon: <img alt='' className='h-6 mr-3' src={FOLDERICON} />,
    alt: "..",
    path: "/bailiff/case-folders",
  },

  {
    id: "3",
    title: "Notifications",
    icon: <img alt='' className='h-6 mr-3' src={BELL_WHITE} />,
    alt: "bell...",
    path: "/bailiff/notification",
  },

  {
    id: "7",
    title: "Profile",
    icon: <img alt='' className='h-6 mr-3' src={SETTINGS} />,
    alt: "user...",
    path: "/bailiff/profile",
  },
];
export const ItStaffSidebar = [
  {
    id: "1",
    title: "Dashboard",
    icon: <img alt='' className='h-6 mr-3' src={OVERVIEW} />,
    alt: "pie...",
    path: "/itstaff",
  },
  {
    id: "2",
    title: "Case Folders",
    icon: <img alt='' className='h-6 mr-3' src={FOLDERICON} />,
    alt: "..",
    path: "/itstaff/case-folders",
  },

  {
    id: "3",
    title: "Notifications",
    icon: <img alt='' className='h-6 mr-3' src={BELL_WHITE} />,
    alt: "bell...",
    path: "/itstaff/notification",
  },

  {
    id: "7",
    title: "Profile",
    icon: <img alt='' className='h-6 mr-3' src={SETTINGS} />,
    alt: "user...",
    path: "/itstaff/profile",
  },
];
export const AdminSidebar = [
  {
    id: "1",
    title: "Dashboard",
    icon: <img alt='' className='h-6 mr-3' src={OVERVIEW} />,
    alt: "pie...",
    path: "/admin/dashboard",
  },
  {
    id: "2",
    title: "All Case Folders",
    icon: <img alt='' className='h-6 mr-3' src={FOLDERICON} />,
    alt: "..",
    path: "/admin/case-folders",
  },

  {
    id: "3",
    title: "Manage Users",
    icon: FaBell,
    alt: "bell...",
    path: "/admin/manage-users",
  },
  {
    id: "4",
    title: "Payments",
    icon: BsCreditCard2FrontFill,
    alt: "bell...",
    path: "/admin/payments",
  },
  {
    id: "5",
    title: "Profile",
    icon: <img alt='' className='h-6 mr-3' src={SETTINGS} />,
    alt: "user...",
    path: "/admin/profile",
  },
];
export interface SidebarDataTypes {
  id: string;
  title: string;
  icon: any;
  alt?: string;
  path: string;
}
