/* eslint-disable import/no-extraneous-dependencies */

import ReactLenis from "@studio-freight/react-lenis";
import { motion, useScroll } from "framer-motion";
import React from "react";

function ScrollContainer({ children }: { children: React.ReactNode }) {
  const { scrollYProgress } = useScroll();

  return (
    <ReactLenis
      options={{
        smoothTouch: true,
        duration: 1.5,
      }}
      root
    >
      <motion.div
        className='progress-bar z-50'
        style={{ scaleX: scrollYProgress }}
      />
      {children}
    </ReactLenis>
  );
}

export default ScrollContainer;
