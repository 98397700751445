export enum UserType {
  LAWYER = "LAWYER",
  NON_LAWYER = "NON LAWYER",
  REGISTRAR_1 = "REGISTRA_1",
  REGISTRAR_2 = "REGISTRA_2",
  REGISTRAR_3 = "REGISTRA_3",
  PRESIDING_JUDGE = "PRESIDING JUDGE",
  PRESIDING_MAGISTRATE = "PRESIDING MAGISTRATE",
  JUDGE = "JUDGE",
  CHIEF_JUDGE = "CHIEF_JUDGE",
  IT_STAFF = "IT_STAFF",
  MAGISTRATE = "MAGISTRATE",
  BAILIFF = "BAILIFF",
  ADMIN = "ADMIN",
}

export enum Status {
  PENDING = " PENDING",
  SUBMITTED = "SUBMITTED",
  AWAITING_SERVICE = "AWAITING_SERVICE",
  READY_FOR_ASSIGNMENT = "READY_FOR_ASSIGNMENT",
  PICKED_UP = "PICKED_UP",
  DELIVERED = "DELIVERED",
  SERVED = "SERVED ",
  ASSIGNED = "ASSIGNED",
  RE_ASSIGNED = "RE_ASSIGNED",
  RECUSE = "RECUSE",
  TRANSFER = "TRANSFER",
  DPP_ADVICE = "DPP_ADVICE",
  RETURNED = "RETURNED",
  ADVICED = "ADVICED",
}
