import "../../index.css";

import { AiOutlineMenu } from "react-icons/ai";

import { BELL_BLACK } from "../../assets";
import { useAppSelector } from "../../hooks";
import { UserPayload } from "../../services/auth/types";
import { selectRole, selectUser } from "../../states/slices/authReducer";
import { getBackgroundColor } from "../../utils";
import Typography from "../Typography";

interface HeaderTypes {
  onMenu?: any;
}

function Header(props: HeaderTypes) {
  const role = useAppSelector(selectRole);
  const user: UserPayload = useAppSelector(selectUser);
  const colors: string = getBackgroundColor(user?.first_name);

  return (
    <div>
      <div
        className={` h-[12vh] flex justify-between bg-white shadow-brand_indigo border-b-8 items-center flex-row w-full px-4 lg:px-8 pt-8 lg:pt-0 pb-4 lg:py-2`}
      >
        <div className='lg:flex lg:flex-1 items-center gap-x-5 mr-3 hidden'>
          <p>
            Hello,{" "}
            <span className='font-bold'>
              {role === "ADMIN" ? "Admin" : `${user?.first_name || "John"}`}
            </span>
          </p>
        </div>
        {/* <div className='flex flex-1 lg:flex-none shrink-0 gap-x-3 items-center h-full justify-start '>
					<Link
						to='/'
						className={`transition ease-in-out delay-150 py-2 px-3 w-16 flex justify-between items-center  hover:-translate-y-1 hover:scale-110  duration-300 text-xs `}
					>
						<img src={LOGO} className='h-full w-full rounded-full' alt='...logo' />
					</Link>
				</div> */}

        <div className='lg:hidden'>
          <AiOutlineMenu className='h-10 text-4xl' onClick={props.onMenu} />
        </div>
        <img alt='' className='h-6 mr-3' src={BELL_BLACK} />
        <div className='flex justify-start'>
          {colors && (
            <div
              className='w-12 h-12  border-4  rounded-full flex justify-center items-center text-white font-bold'
              style={{
                backgroundColor: colors,
              }}
            >
              {user?.first_name[0]}
            </div>
          )}
          <div className='ml-2 hidden md:block'>
            <Typography variant='label'>
              {role === "ADMIN"
                ? "Hello Admin"
                : `${user?.first_name || "JOHN"} 	${user?.last_name || "DOE"}`}
            </Typography>
            <p className='text-xs'>{user.email}</p>
            <Typography textStyle='text-gray-500' variant='caption'>
              {role}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
