/* eslint-disable react/no-unescaped-entities */
import * as React from "react";
import { Zoom } from "react-awesome-reveal";

import FeaturesSlide from "../../../components/Slider/FeaturesSlide";

const Features = () => {
  const [openfaq, setOpenFaq] = React.useState(false);

  return (
    <div
      className=' py-[7%] flex flex-col px-4 lg:px-20  min-h-[600px] lg:h-screen '
      id='faqs'
    >
      <Zoom triggerOnce={false}>
        <div className='text-4xl font-bold mb-10 lg:mb-20  tracking-wide mx-auto leading-10 text-center text-green-950 max-md:max-w-full'>
          Features and Services
        </div>
      </Zoom>
      <div>
        <FeaturesSlide />
      </div>
    </div>
  );
};

export default Features;
