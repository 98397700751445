/* eslint-disable react/jsx-sort-props */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";

import Loader from "./components/Loader";
import ScrollToTopEffect from "./components/ScrollToTopEffect";
import About from "./features/about";
import Login from "./features/auth/Login";
import CauseList from "./features/cause-list";
import CourtDocuments from "./features/CourtDocuments";
import Home from "./features/Home";
import News from "./features/News";
import Details from "./features/News/details";
import ChiefRegistrars from "./features/Officials/ChiefRegistrars";
import Judges from "./features/Officials/Judges";
import Magistrates from "./features/Officials/Magistrates";
import PrincipalOfficers from "./features/Officials/PrincipalsOfficers";
import RetiredChiefJudges from "./features/Officials/RetiredChiefJudges";
import RetiredChiefRegistrars from "./features/Officials/RetiredChiefRegistrars";
import RetiredJudges from "./features/Officials/RetiredJudges";
import JoinRoom from "./features/Room/JoinRoom";
import Room from "./features/Room/Room";
import VirtualHearing from "./features/VirtualHearing ";
import { useAppSelector } from "./hooks";
import MainRoutes from "./Routes";
import { PrivateRoutes } from "./Routes/private";
import { globalLoading } from "./states/slices/authReducer";

function App() {
  const loading = useAppSelector(globalLoading);

  const [preLoader, setPreloader] = useState<boolean>(true);

  useEffect(() => {
    const preloaderTimer = setTimeout(() => {
      setPreloader(false);
    }, 1000);

    return () => {
      // Clear the timer if the component is unmounted before the preloader timeout
      clearTimeout(preloaderTimer);
    };
  }, []);
  return (
    <>
      <ScrollToTopEffect />
      {preLoader ? (
        <Loader />
      ) : (
        <Routes>
          <Route
            element={
              <PrivateRoutes>
                <MainRoutes />
              </PrivateRoutes>
            }
            path='/*'
          />

          <Route element={<Login />} path='/login' />
          <Route element={<Home />} path='/' />
          <Route element={<JoinRoom />} path='/join-hearing' />
          <Route element={<Room />} path='/hearing' />
          <Route element={<VirtualHearing />} path='/virtual-hearing' />
          <Route element={<About />} path='/about-us' />
          <Route element={<News />} path='/news-and-events' />
          <Route element={<Details />} path='/news-and-events/details' />
          <Route element={<CourtDocuments />} path='/court-documents' />
          <Route element={<CauseList />} path='/cause-lists' />
          <Route element={<Judges />} path='/judges' />
          <Route element={<Magistrates />} path='/magistrates' />
          <Route
            element={<RetiredChiefJudges />}
            path='/retired-chief-judges'
          />
          <Route
            element={<ChiefRegistrars />}
            path='/chief-Registrar-dcr-judges'
          />
          <Route
            element={<RetiredChiefRegistrars />}
            path='/retired-chief-registrars'
          />
          <Route element={<PrincipalOfficers />} path='/principal-officers' />
          <Route element={<RetiredJudges />} path='/retired-judges' />

          {/* <Route element={<Navigate replace to='/login' />} path='/' /> */}
        </Routes>
      )}
      {loading && <Loader />}

      <Toaster position='top-right' reverseOrder={false} />
    </>
  );
}

export default App;
