/* eslint-disable no-console */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Form, Formik } from "formik";
import React from "react";
import { Zoom } from "react-awesome-reveal";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

import Button from "../../components/button";
import Input from "../../components/form/Input";
import { API } from "../../config";

function JoinRoom() {
  const [urlparms] = useSearchParams();
  const roomName = urlparms.get("title");
  const navigate = useNavigate();
  const JoinRoomApi = async (data: any) => {
    const response = await API.post("cause-lists/join-meeting", data);
    return response?.data;
  };
  return (
    <div className='flex justify-center items-center h-screen w-screen'>
      <div className='flex relative flex-col items-center py-12 mt-20 mb-12 max-w-full  bg-white border-solid  border-[0.89px] border-[color:var(--COLOUR-2,#CACACA)] rounded-[32px] w-[611px] max-md:my-10'>
        <div className='mt-1.5 text-2xl font-semibold text-black whitespace-nowrap'>
          Join Hearing
        </div>

        <Zoom>
          <Formik
            initialValues={{
              participant_name: "",
            }}
            // validationSchema={loginSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const payload: any = {
                room_name: roomName,
                participant_name: values.participant_name,
              };
              try {
                const response = await JoinRoomApi(payload);

                if (response) {
                  const { data, message } = response;
                  console.log(data);

                  toast.success(message, {
                    duration: 7000, // Duration for which the toast is displayed (in milliseconds)
                  });
                  navigate(`/hearing?token=${data.token}`);
                }
              } catch (error: any) {
                toast.error(error?.response?.data?.message, {
                  duration: 10_000,
                });
              }
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              values,
              handleChange,
              /* and other goodies */
            }) => (
              <Form className='flex flex-col pt-3 md:pt-4 px-4 md:px-8 lg:pt-6'>
                <div className='flex justify-between items-center flex-wrap '>
                  <div className='flex flex-col pt-2  w-full'>
                    <label className='text-sm' htmlFor='email'>
                      Participant Name
                    </label>
                    <Input
                      name='participant_name'
                      placeholder='Enter your participant name'
                      type='text'
                    />
                  </div>
                </div>

                <Button
                  bgColor='bg-brand_blue'
                  className=' mb-2  mt-4  w-full py-4 rounded-2xl'
                  isLoading={isSubmitting}
                  onClick={handleSubmit}
                  textColor='text-white'
                  title='Join'
                  type='button'
                />
              </Form>
            )}
          </Formik>
        </Zoom>
        {/* <div className='text-center pt-4 pb-12'>
  <p className='text-sm'>
    Don't have an account?{" "}
    <Link className='text-brand_blue font-semibold' to='/signup'>
      Sign up
    </Link>
  </p>
</div> */}
      </div>
    </div>
  );
}

export default JoinRoom;
