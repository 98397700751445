export default {
  submitCaseFolder: (case_folder_id: string) =>
    `lawyer/${case_folder_id}/submit`,
  addParties: (case_folder_id: string) =>
    `lawyer/case-folders/${case_folder_id}/add-parties`,
  addDocument: (case_folder_id: string | null) =>
    `lawyer/case-folders/${case_folder_id}/add-document`,

  createCaseFolder: "lawyer/case-folders",
  submitCase: (case_folder_id: string | null) =>
    `lawyer/case-folders/${case_folder_id}/submit`,
  joinCase: "lawyer/case-folders/join",
  dashboard: "dashboard",

  resolveQueries: (case_folder_id: string) =>
    `case-folders/${case_folder_id}/resolve-case-queries`,
  getCaseDocuments: (case_folder_id: string | null) =>
    `/case-folders/${case_folder_id}/case-documents`,
  deleteDocuments: (case_folder_id: string) =>
    `/lawyer/case-documents/${case_folder_id}`,
  renameDocuments: (case_folder_id: string | undefined) =>
    `lawyer/case-documents/${case_folder_id}/rename-title`,
  getCauselist: "cause-lists/user",
  addCauselist: "cause-lists",
};
