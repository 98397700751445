/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import { Form, Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { Zoom } from "react-reveal";
import { useNavigate } from "react-router-dom";

import Button from "../../../components/button";
import Input from "../../../components/form/Input";
import { useAppSelector } from "../../../hooks";
import { UpdateProfileApi } from "../../../services/auth/auth.service";
import { selectUser } from "../../../states/slices/authReducer";

function Profile() {
  const [files, setFiles] = useState<any>([]);
  const [barFile, setBarFile] = useState<any>([]);
  const user = useAppSelector(selectUser);
  const updateMutation = useMutation(UpdateProfileApi);
  const navigate = useNavigate();

  return (
    <div className='flex flex-col justify-center'>
      <div className='flex overflow-hidden relative flex-col justify-center items-center px-10 py-12 w-full  max-md:px-5 max-md:max-w-full'>
        <div className='mt-1.5 text-2xl font-semibold text-black whitespace-nowrap mb-6'>
          Setting
        </div>
        <div className='flex relative flex-col items-center  pb-8  mb-12 max-w-full bg-white shadow-box rounded-[32px] w-[511px] '>
          <Zoom triggerOnce={false}>
            <Formik
              initialValues={{
                full_name: `${user?.first_name} ${user?.last_name}` ?? "",
                email: user?.email ?? "",
                password: "",
                phone_number: user?.phone_number ?? "",
                whatsapp_number: user?.whatsapp_number ?? "",
                scn: user?.scn ?? "",
                gender: user?.gender ?? "",
                address: "",
                alternative_email: user?.email ?? "",
                call_to_bar_certificate: null,
              }}
              // validationSchema={signupSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                const nameParts = values.full_name.split(" ");
                const firstname = nameParts[0];
                const lastname = nameParts.slice(1).join(" ");
                const formData: any = new FormData();

                formData.append("first_name", firstname);
                formData.append("last_name", lastname);
                formData.append("email", values.email); // Use values.email instead of email

                formData.append("phone_number", values.phone_number);
                formData.append("whatsapp_number", values.whatsapp_number);

                formData.append("alternative_email", values.alternative_email);

                try {
                  const response = await updateMutation.mutateAsync(formData);

                  if (response) {
                    toast.success(response.message);

                    // removeAll();
                  }
                } catch (error: any) {
                  toast.error(error?.response?.data?.message);
                }
              }}
            >
              {({
                handleSubmit,
                isSubmitting,
                values,
                handleChange,
                /* and other goodies */
              }) => (
                <Form className='flex flex-col pt-3 md:pt-4 px-4 md:px-10 lg:pt-6'>
                  {/* <div className='flex justify-center items-center flex-wrap '>
                    <div className=' lg:mr-8 mb-4 lg:mb-0  w-full lg:w-[30%] flex flex-col items-center  py-4 rounded-lg'>
                      <div className=' w-full px-6'>
                        <div
                          {...getRootProps()}
                          className='flex justify-center items-center flex-col rounded-full relative'
                        >
                          {files.length > 0 || user?.profile_photo ? (
                            profilePic
                          ) : (
                            <img
                              alt=''
                              className='h-20 w-20 rounded-full mx-auto'
                              src={USER_ICON}
                            />
                          )}
                          <img
                            alt=''
                            className='h-5 ml-2 absolute right-2 bottom-0'
                            src={EDIT}
                          />
                          <input {...getInputProps()} />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className='flex justify-between items-center flex-wrap  '>
                    <div className='flex flex-col   w-full mb-6'>
                      <label className='mb-2'>Full name</label>
                      <Input
                        name='full_name'
                        placeholder='Enter your full name'
                        type='text'
                      />
                    </div>

                    <div className='flex flex-col  w-full '>
                      <label className='mb-2'>Phone number</label>
                      <Input
                        name='phone_number'
                        placeholder='Phone number'
                        type='text'
                      />
                    </div>
                  </div>

                  <Button
                    bgColor='bg-brand_blue'
                    className=' mb-2  mt-2  w-full lg:w-[60%] py-3 rounded-2xl mx-auto'
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                    textColor='text-white'
                    title='update'
                    type='button'
                  />
                </Form>
              )}
            </Formik>
          </Zoom>
        </div>
      </div>
    </div>
  );
}

export default Profile;
