// import Swiper core and required modules
import { useNavigate } from "react-router-dom";
import { A11y, Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default () => {
  const navigate = useNavigate();
  return (
    <Swiper
      // install Swiper modules
      autoplay={{
        delay: 10_000,
        disableOnInteraction: true,
      }}
      modules={[Navigation, A11y, Autoplay]}
      // onSlideChange={() => console.log("slide change")}
      // onSwiper={(swiper) => console.log(swiper)}
      // pagination={{ clickable: true }}

      slidesPerView={1}
      spaceBetween={0}
    >
      <SwiperSlide>
        <div className='overflow-hidden flex justify-start items-start min-h-[600px] lg:h-screen first-bg self-center px-16 pt-8  w-full bg-white  max-md:px-5 max-md:max-w-full'>
          {/* <div className='flex gap-5 flex-col w-full relative'>
            <Fade
              className='flex flex-col w-full lg:w-[40%] mt-8 bg-black/80 py-8 rounded-md'
              direction='left'
              triggerOnce={false}
            >
              <div className='flex flex-col self-stretch my-auto max-md:mt-10 max-w-full'>
                <div className='text-4xl font-bold tracking-normal text-green-100  max-md:max-w-full max-md:text-4xl max-md:leading-[49px] text-center'>
                  Enugu State Judiciary
                </div>
                <div className='mt-10 text-base font-medium tracking-normal text-green-100 leading-6 text-BRAND max-md:max-w-full text-center w-full  mx-auto'>
                  The Enugu State Judiciary official website for speeding up the
                  litigation process for the judiciary system in Enugu State.
                </div>
              </div>
            </Fade>
          </div>
          <Fade
            className='flex absolute bottom-[10%] right-0 flex-col w-full lg:w-[30%] mt-8 bg-black/80 py-8 rounded-md'
            direction='left'
            triggerOnce={false}
          >
            <div className='flex flex-col self-stretch my-auto max-md:mt-10 max-w-full'>
              <div className='text-2xl font-bold tracking-normal text-green-100  max-md:max-w-full max-md:text-4xl max-md:leading-[49px] text-center'>
                HON. JUSTICE A.R OZOEMENA CHIEF JUDGE OF ENUGU STATE
              </div>
              <div className='mt-10 text-base font-medium tracking-normal text-green-100 leading-6 text-BRAND max-md:max-w-full text-center w-full  mx-auto'>
                CHIEF JUDGE OF ENUGU STATE
              </div>
            </div>
          </Fade> */}
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className='overflow-hidden flex justify-center items-center min-h-[600px] lg:h-screen seventh-bg self-center px-16 pt-8 -mt-7 w-full bg-white  max-md:px-5 max-md:max-w-full'>
          {/* <div className='flex gap-5 flex-col w-full'>
            <Fade
              className='flex flex-col w-full lg:w-[60%] mx-auto '
              direction='left'
              triggerOnce={false}
            >
              <div className='flex flex-col self-stretch my-auto max-md:mt-10 max-w-full'>
                <div className='text-7xl font-bold tracking-normal text-green-100  max-md:max-w-full max-md:text-4xl max-md:leading-[49px] text-center'>
                  Scale up the Legal System with ESCH
                </div>
                <div className='mt-20 text-base font-medium tracking-normal text-green-100 leading-6 text-BRAND max-md:max-w-full text-center w-full lg:w-[50%] mx-auto'>
                  The ESCH is a technology solution aimed at speeding up the
                  litigation process for the judiciary system in Enugu State.
                </div>
              </div>
            </Fade>
            <Fade
              className='flex flex-col mx-auto  max-md:ml-0 max-md:w-full'
              direction='right'
              triggerOnce={false}
            />
          </div> */}
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className='overflow-hidden flex justify-center items-center min-h-[600px] lg:h-screen second-bg self-center px-16 pt-8 -mt-7 w-full bg-white  max-md:px-5 max-md:max-w-full'>
          {/* <div className='flex gap-5 flex-col w-full'>
            <Fade
              className='flex flex-col w-full lg:w-[60%] mx-auto '
              direction='left'
              triggerOnce={false}
            >
              <div className='flex flex-col self-stretch my-auto max-md:mt-10 max-w-full'>
                <div className='text-7xl font-bold tracking-normal text-green-100  max-md:max-w-full max-md:text-4xl max-md:leading-[49px] text-center'>
                  Scale up the Legal System with ESCH
                </div>
                <div className='mt-20 text-base font-medium tracking-normal text-green-100 leading-6 text-BRAND max-md:max-w-full text-center w-full lg:w-[50%] mx-auto'>
                  The ESCH is a technology solution aimed at speeding up the
                  litigation process for the judiciary system in Enugu State.
                </div>
              </div>
            </Fade>
          </div> */}
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className='overflow-hidden flex justify-center items-center min-h-[600px] lg:h-screen third-bg self-center px-16 pt-8 -mt-7 w-full bg-white  max-md:px-5 max-md:max-w-full'>
          {/* <div className='flex gap-5 flex-col w-full'>
            <Fade
              className='flex flex-col w-full lg:w-[60%] mx-auto '
              direction='left'
              triggerOnce={false}
            >
              <div className='flex flex-col self-stretch my-auto max-md:mt-10 max-w-full'>
                <div className='text-7xl font-bold tracking-normal text-green-100  max-md:max-w-full max-md:text-4xl max-md:leading-[49px] text-center'>
                  Scale up the Legal System with ESCH
                </div>
                <div className='mt-20 text-base font-medium tracking-normal text-green-100 leading-6 text-BRAND max-md:max-w-full text-center w-full lg:w-[50%] mx-auto'>
                  The ESCH is a technology solution aimed at speeding up the
                  litigation process for the judiciary system in Enugu State.
                </div>
              </div>
            </Fade>
            <Fade
              className='flex flex-col mx-auto  max-md:ml-0 max-md:w-full'
              direction='right'
              triggerOnce={false}
            />
          </div> */}
        </div>
      </SwiperSlide>

      {/* <SwiperSlide>
        <div className='overflow-hidden flex justify-center items-center min-h-[600px] lg:h-screen eight-bg self-center px-16 pt-8 -mt-7 w-full bg-white  max-md:px-5 max-md:max-w-full' />
      </SwiperSlide> */}
    </Swiper>
  );
};
