import * as yup from "yup";

const createCaseSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  type: yup.string().required("Title is required"),
  address: yup.string().required("Address is required"),
  phone_number: yup
    .string()
    .matches(/^\d{11}$/, "Phone number must be 11 digits")
    .required("Phone number is required"),
  whatsapp_number: yup
    .string()
    .matches(/^\d{11}$/, "WhatsApp number must be 11 digits")
    .required("WhatsApp number is required"),
  email: yup
    .string()
    .email("Please enter a valid email.")
    .required("Email is required"),
});

export default createCaseSchema;
